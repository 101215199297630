import { Heading } from "theme-ui";

import { useAppSelector, useAppDispatch } from "@brinks/common/hooks/hooks";
import SelectLocation from "./SelectLocation";
import { useEffect, useState } from "react";
import ReviewDeposite from "./ReviewDeposite";
import AddSealBag from "./AddSealbag";
import Wrapper from "../../../Shared/Wrapper/Wrapper";
import PageHeader from "../../../Shared/PageHeader";
import { setCashDevice } from "@brinks/common/reducers/registerSealbagSlice";
import { useTranslation } from "react-i18next";
import { BackIcon } from "@brinks/common/Icons/Components/back";
import TagManager from "react-gtm-module";
import { setCurrentMerchant } from "@brinks/common/reducers/merchantsDetailSlice";
import { setCleanLocation } from "@brinks/common/reducers/storeLocationSlice";

export default function RegisterSealbag() {

  useEffect(() => {
    TagManager.dataLayer({ 
      dataLayer: {
        event: "page_view",
        Page_title: "Deposit sealbag | Add deposit"
      } 
    })
  }, []);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [step, updateStep] = useState(1);

  const { denominations } = useAppSelector(
    (state) => state.registerSealbagSlice
  );

  useEffect(() => {
    dispatch(setCurrentMerchant(null));
    dispatch(setCleanLocation(null));
    dispatch(setCashDevice(null));
  }, []);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        Page_title: "Deposit sealbag | Add deposit",
      },
    });
  }, []);

  const nextStep = () => {
    updateStep(step + 1);
  };
  
  const previousStep = () => {
    TagManager.dataLayer({ 
      dataLayer: {
        event: "Click_event",
        Page_title: "Deposit sealbag | Add deposit",
        Action: "Previous"
      } 
    });
    updateStep(step - 1);
  };

  const getCurrentStep = () => {
    
    switch (step) {
      case 1:
        return <SelectLocation nextStep={nextStep} />;
      case 2:
        return (
          <AddSealBag
            nextStep={nextStep}
            denominations={denominations}
            updateStep={updateStep}
          />
        );
      case 3:
        return (
          <ReviewDeposite updateStep={updateStep} previousStep={previousStep} tagType={"depositSealbag"} />
        );
      default:
      // do nothing
    }
  };

  return (
    <>
      <PageHeader>
        <Heading>
          {step !== 1 ? (
            <>
              <span
                onClick={(e: any) => previousStep()}
                style={{ marginRight: "20px", cursor: "pointer" }}
              >
                <BackIcon color={"black"} />
              </span>
            </>
          ) : (
            ""
          )}
          {step === 3 ? t("Header.review_deposit") : t("Header.add_deposit")}
        </Heading>
     
      </PageHeader>

      <Wrapper>{getCurrentStep()}</Wrapper>
    </>
  );
}
