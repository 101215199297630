import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Heading, Flex, Text } from "theme-ui";
import { FolderIcon } from "@brinks/common/Icons/Components/folder";
import { FolderAddIcon } from "@brinks/common/Icons/Components/folder_add";
import { useNavigate } from "react-router-dom";
import TagManager from "react-gtm-module";

export default function Support() {

  useEffect(() => {
    TagManager.dataLayer({ 
      dataLayer: {
        event: "page_view",
        Page_title: "Support | Start screen"
      } 
    })
   
  }, [])
  
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Box
        bg="white"
        data-testid="PageHeader"
        py={23}
        px={64}
        sx={{
          position: "fixed",
          width: "100%",
          height: "100%",
          mb: 80,
        }}
      >
        <Flex>
          <Heading
            sx={{
              height: 100,
            }}
          >
            {t("support.support_label")}
          </Heading>
        </Flex>

        <Flex
          sx={{
            display: "block",
          }}
        >
          <Flex 
            onClick={() => {
              navigate(`/support/list`);
              TagManager.dataLayer({ 
                dataLayer: {
                  event: "Click_event",
                  Page_title: "Support | Start screen",
                  Action: "List cases" 
                } 
              });
            }}
            sx={{
              width: 430,
              height: 142,
              border: "1px solid",
              borderColor: "shade_200",
              borderRadius: 16,
              padding: 40,
              backgroundColor: "white",
              cursor: "pointer",
            }}
          >
            <FolderIcon
              color={"order_primary"}
            />
            <Flex
              ml={37}
              sx={{
                flexDirection: "column",
              }}
            >
              <Text
                mt={10}
                sx={{
                  fontSize: "medium",
                  fontFamily: "heading",
                  fontWeight: "heading",
                  lineHeight: "location_lable",
                  color: "black",
                }}
              >
                {t("support.overview_cases")}
              </Text>
              <Text
                mt={12}
                sx={{
                  fontSize: "subText",
                  fontFamily: "body",
                  fontWeight: "weight_400",
                  lineHeight: "register_bag",
                  color: "shade_400",
                }}
              >
                {t("support.overview_description")}
              </Text>
            </Flex>
          </Flex>
          <Flex
            onClick={() => {
              navigate(`/support/create`);
              TagManager.dataLayer({ 
                dataLayer: {
                  event: "Click_event",
                  Page_title: "Support | Start screen",
                  Action: "Create a case"
                } 
              })
            }}
            ml={40}
            sx={{
              width: 434,
              height: 142,
              border: "1px solid",
              borderColor: "shade_200",
              borderRadius: 16,
              padding: 40,
              backgroundColor: "white",
              cursor: "pointer",
            }}
          >
            <FolderAddIcon
              color={"order_primary"}
            />
            <Flex
              ml={37}
              sx={{
                flexDirection: "column",
              }}
            >
              <Text
                mt={10}
                sx={{
                  fontSize: "medium",
                  fontFamily: "heading",
                  fontWeight: "heading",
                  lineHeight: "location_lable",
                  color: "black",
                }}
              >
                {t("support.create_a_case")}
              </Text>
              <Text
                mt={12}
                sx={{
                  fontSize: "subText",
                  fontFamily: "body",
                  fontWeight: "weight_400",
                  lineHeight: "register_bag",
                  color: "shade_400",
                }}
              >
                {t("support.create_case_description")}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </>
  );
}
