import { OrderItemDetails } from "@brinks/common/api/Api";
import { useAppSelector,useAppDispatch } from "@brinks/common/hooks/hooks";
import { localizeAmount } from "@brinks/common/utils";
import React,  { useEffect }from "react";
import {Flex, Text, Divider } from "theme-ui";
import { getSku, getSkus } from "@brinks/common/reducers/skusDetailSlice";

interface Props {
  orderItemDetail: OrderItemDetails;
  isNotesQty: boolean;
}
export default function OrderDetailDinomiantion({
  orderItemDetail,
  isNotesQty,
}: Props) {
  const currentyType = orderItemDetail.denomination?.type;

  const { currentSku, skus } = useAppSelector((state) => state.skusDetailSlice);

  const dispatch = useAppDispatch();

  const onGetSkus = () => {
    dispatch(getSkus(undefined));
  };

  useEffect(() => {
     onGetSkus();
  }, []);

  return (
    <>
      <Divider sx={{ width: 945 }} mt={16} mb={24} color="divider" />
      <Flex
        sx={{
          width: currentyType === "Note" && isNotesQty ? 450 : 800,
          justifyContent: "space-between",
        }}
      >
        <Text
          sx={{
            color: "shade_500",
            fontSize: "body",
            fontWeight: "weight_400",
            width: 70,
          }}
        >
          {localizeAmount(
            orderItemDetail.denomination?.faceValue || 0,
            orderItemDetail?.denomination?.currency || "", 
            orderItemDetail?.denomination?.currency || "" 
          )}
        </Text>
          <Text
            sx={{
              color: "shade_500",
              fontSize: "body",
              fontWeight: "weight_400",
              width: 100,
            }}
          >
             {currentyType === "Note"? orderItemDetail.increments : skus?.filter((item ) => item.id === orderItemDetail.skuId)[0].quantity}
          </Text>
        <Text
          sx={{
            color: "shade_500",
            fontSize: "body",
            fontWeight: "weight_400",
            width: 150,
          }}
        >
          {orderItemDetail.quantity}
        </Text>
      </Flex>
    </>
  );
}
