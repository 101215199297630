// ---------------------------------------------------------
// EXTERNAL IMPORTS
// ---------------------------------------------------------
import TagManager from "react-gtm-module";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Box, Divider, Grid, Flex, Heading, Text } from "theme-ui";

// ---------------------------------------------------------
// INTERNAL IMPORTS
// ---------------------------------------------------------
import Loader from "../../../Shared/Loader";
import LocationHeader from "./LocationHeader";
import LocationDetails from "./LocationDetails";
import PageHeader from "../../../Shared/PageHeader";
import Wrapper from "../../../Shared/Wrapper/Wrapper";
import LocationOpeningTimes from "./LocationOpeningTimes";
import LocationContactDetails from "./LocationContactDetails";

import { useAppSelector, useAppDispatch } from "@brinks/common/hooks/hooks";
import { ReactComponent as Back } from "@brinks/common/Icons/back_arrow.svg";
import { getLocationDetailsWithMerchant } from "@brinks/common/reducers/locationDetailWithMerchantSlice";

export const LocationDetailsPage = () => {

  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { loading, locationDetailsWithMerchant } = useAppSelector(
    (state) => state.locationDetailWithMerchantSlice
  );
  
  const locationDetailsContacts: any = locationDetailsWithMerchant;
  
  useEffect(() => {
    TagManager.dataLayer({ 
      dataLayer: {
        event: "page_view",
        Page_title: "Manage locations | Detail view"
      } 
    })
  }, []);

  useEffect(() => {
    const obj = { locationId: id };
    dispatch(getLocationDetailsWithMerchant(obj));
  }, [id]);

  if (loading) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  return (
    <>
      <PageHeader>
        <div
          onClick={() => {
            TagManager.dataLayer({ 
              dataLayer: {
                Action: "Back",
                event: "Click_event",
                Page_title: "Manage locations | Detail view",
              } 
            })
            navigate(`/locations`);
          }}
        >
          <span style={{ cursor: "pointer" }}>
            <Back />
          </span>
          <Text
            mx={10}
            sx={{
              fontSize: "body",
              color: "primary",
              cursor: "pointer",
              fontWeight: "weight_400",
            }}
          >
            {t("ManageSealBags.back")}
          </Text>
        </div>
        <Heading ml={20}> {t("Header.location_details")}</Heading>
      </PageHeader>
      <Wrapper>
        <Grid gap={2} columns={[1, "1fr 4fr"]}>
          <Box>
            <Flex
              pt={20}
              pl={4}
              sx={{
                fontSize: "medium",
                fontWeight: "bold",
                fontFamily: "heading",
              }}
            >
              {t("address_details")}
            </Flex>
          </Box>
          <Box sx={{ width: 746 }}>
            <Card
              bg="white"
              data-testid="LocationDetails-container-card1"
              sx={{
                margin: "0 auto",
                borderRadius: "5px",
                alignItems: "center",
                padding: "39px 32px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <LocationHeader locationDetails={locationDetailsWithMerchant} />
              <Divider mt={10} color="divider" />
              <LocationDetails locationDetails={locationDetailsWithMerchant} />
            </Card>
          </Box>
        </Grid>
        {locationDetailsContacts && locationDetailsContacts?.contacts?.length > 0 && 
        <Grid gap={2} pt={54} columns={[1, "1fr 4fr"]}>
          <Box>
            <Flex
              pt={20}
              pl={4}
              sx={{
                fontSize: "medium",
                fontWeight: "bold",
                fontFamily: "heading",
              }}
            >
              {t("ManageLocations.contact_details")}
            </Flex>
          </Box>
         
          <Box sx={{ width: 746 }}>
            <Card
              bg="white"
              data-testid="LocationDetails-container-card2"
              sx={{
                margin: "0 auto",
                borderRadius: "5px",
                alignItems: "center",
                padding: "39px 32px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <LocationContactDetails locationDetails={locationDetailsWithMerchant} />
            </Card>
          </Box>
       
        </Grid>
           }
        <Grid gap={2} pt={54} columns={[1, "1fr 4fr"]}>
          <Box>
            <Flex
              pt={20}
              pl={4}
              sx={{
                fontSize: "medium",
                fontWeight: "bold",
                fontFamily: "heading",
              }}
            >
              {t("ManageLocations.opening_times")}
            </Flex>
          </Box>
          <Box sx={{ width: 746 }}>
            <Card
              bg="white"
              data-testid="LocationDetails-container-card3"
              sx={{
                margin: "0 auto",
                borderRadius: "5px",
                alignItems: "center",
                padding: "39px 32px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <LocationOpeningTimes locationDetails={locationDetailsWithMerchant}/>
            </Card>
          </Box>
        </Grid>
      </Wrapper>
    </>
  );
}
