import { Box, Text, Flex } from "theme-ui";
import Select, { StylesConfig } from "react-select";
import { CSSProperties } from "react";
import { useAppDispatch, useAppSelector } from "@brinks/common/hooks/hooks";
import { useTranslation } from "react-i18next";
import { CashDevice as ICashDevice } from "@brinks/common/api/Api";

interface Props {
  cashDevices: any;
  onUpdateCashDevice: (cashDevice: ICashDevice) => void;
}
const customControlStyles: CSSProperties = {
  height: "61px",
};

const customStyles: StylesConfig<any> = {
  control: (provided, state) => {
    return {
      ...provided,
      ...customControlStyles,
    };
  },
};

export const CashDevice = ({ cashDevices, onUpdateCashDevice }: Props) => {
  const { selectedLocation } = useAppSelector(
    (state) => state.registerSealbagSlice
  );
  
  const { selectedCashDevice } = useAppSelector(
    (state) => state.registerSealbagSlice
  );
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <Flex
      data-testid="advanceFilterCashDevice"
      bg={"white"}
      sx={{
        flexDirection: "column",
        zIndex: 999,
        paddingTop: 20,
      }}
    >
      <Text
        sx={{
          fontWeight: "body",
          fontSize: "subText",
        }}
        data-testid="flex-cashDevice-text"
      >
        {t("ManageSealBags.cash_device_lable")}
      </Text>
      <Box
        data-testid="advanceFilter-CashDevice-box"
        className="custome-select"
        sx={{
          pt: "8px",
          width: "343px",
        }}
      >
        <Select
          data-testid="advanceFilter-CashDevice-select"
          value={selectedLocation ?  selectedCashDevice : null}
          isSearchable={true}
          styles={customStyles}
          onChange={(e: any) => {
            onUpdateCashDevice(e);
          }}
          options={selectedLocation && cashDevices || "Fetching..."}
          getOptionLabel ={(option) => option.deviceName && option.deviceId ? `${option.deviceName} - ${option.deviceId}` : option.deviceName}
          getOptionValue ={(option)=>option.id}
          placeholder={t("ManageSealBags.select_cash_device")}
        />
      </Box>
    </Flex>
  );
};
export default CashDevice;
