// ---------------------------------------------------------
// EXTERNAL IMPORTS
// ---------------------------------------------------------
import TagManager from "react-gtm-module";
import Select, { StylesConfig } from "react-select";

import { Flex, Input, Text } from "theme-ui";
import { useTranslation } from "react-i18next";
import { useState, useEffect, CSSProperties, useMemo } from "react";

// ---------------------------------------------------------
// INTERNAL IMPORTS
// ---------------------------------------------------------
import { Merchant } from "@brinks/common/api/Api";
import { useAppDispatch, useAppSelector } from "@brinks/common/hooks/hooks";
// ---------------------------------------------------------
// STYLE
// ---------------------------------------------------------
const customControlStyles: CSSProperties = {
  margin: "25px 12px 12px 0",
};

const customStyles: StylesConfig<any> = {
  control: (provided, state) => {
    return { ...provided, ...customControlStyles };
  },
  dropdownIndicator: (provided, state) => ({
    paddingTop: "5px",
    color: "shade_400",
    paddingRight: "20px",
  }),
};

// ---------------------------------------------------------
// TYPES
// ---------------------------------------------------------
interface Options {
  label: string;
  value: string;
}

interface MerchantFilterSelectorProps {
  isDirty: boolean;
  value: null | string;
  merchants: Merchant[];
  onChange: (value: null | string) => void;
}

export const MerchantFilterSelector = ({
  value,
  isDirty,
  onChange,
  merchants,
}: MerchantFilterSelectorProps) => {
  const { t } = useTranslation();

  const [options, setOptions] = useState<Options[]>([
    { value: "ALL", label: t("UserLocationFilter.all") },
  ]);

  const { storeLocations, loading } = useAppSelector(
    (state) => state.storeLocationSlice
  );

  useEffect(() => {
    if (!storeLocations) return;
    const merchantMap = new Map();
  
    storeLocations
      .filter(storeLocation => storeLocation.merchant)
      .forEach(storeLocation => {
        const merchantId = storeLocation?.merchant?.id;
        
        if (!merchantMap.has(merchantId)) {
          merchantMap.set(merchantId, storeLocation.merchant);
        }
      });
  
    const uniqueMerchants = Array.from(merchantMap.values());

    const merchantOptions = uniqueMerchants.map((obj: any) => {
      return { ...obj, label: obj.name, value: obj.id };
    });

    setOptions([
      { value: "ALL", label: t("ManageOrders.all") },
      ...merchantOptions,
    ]);
  
  }, [storeLocations]);

  const handleChange = (event: Options) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "Click_event",
        Action: "Merchant " + event.value,
        Page_title: "Orders management | Order change",
      },
    });

    onChange(event.value === "ALL" ? null : event.value);
  };

  const valueSelected = useMemo(() => {
    if (value === null) {
      return isDirty
        ? { value: "ALL", label: t("UserLocationFilter.all") }
        : null;
    } else {
      return options.find((option) => {
        return option.value === value;
      });
    }
  }, [value, isDirty]);

  const hasOneAccount = options.length === 2 && options.find(x => x.value.toLowerCase() === 'all');

  useEffect(() => {
    if (hasOneAccount) {
      handleChange(options[1]);
    }
  }, [hasOneAccount]);

  return (
    <Flex sx={{ width: "100%", flexDirection: "column" }}>
      <Text sx={{ fontWeight: "body" }} data-testid="flex-location-text">
        {t("Dashboard.merchant_label")}
      </Text>
      {options.length === 2 && options.find(x => x.value.toLowerCase() === 'all') ? (
        <Input
          contentEditable={false}
          placeholder={`${t("ManageOrders.merchant_placeholder")}`}
          value={options[1].label}
        />
      ) : (
        <Select
          options={options}
          value={valueSelected}
          styles={customStyles}
          onChange={handleChange}
          placeholder={`${t("ManageOrders.merchant_placeholder")}`}
          data-testid="advanceFilter-OrderChangeMerchantSelector-select"
        />
      )}
    </Flex>
  );
};
