import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Heading, Flex, Text, Alert, Close } from "theme-ui";
import { Order_ChangeIcon } from "@brinks/common/Icons/Components/order_change";
import { OrderPickupIcon } from "@brinks/common/Icons/Components/order_pickup";
import { SuccessIcon } from "@brinks/common/Icons/Components/success_msg";
import { ViewList } from "@brinks/common/Icons/Components/view-list";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@brinks/common/hooks/hooks";
import {
  setSuccessOrderChangeMsg,
  setSuccessOrderPickupMsg,
} from "@brinks/common/reducers/orderDetailsSlice";
import TagManager from "react-gtm-module";

export default function Orders() {

  useEffect(() => {
    TagManager.dataLayer({ 
      dataLayer: {
        event: "page_view",
        Page_title: "Order Management | Start screen"
      } 
    })
   
  }, [])
  
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { successOrderChange, successOrderPickup } = useAppSelector(
    (state) => state.orderDetailsSlice
  );
  useEffect(() => {
    if (successOrderChange) {
      setTimeout(() => {
        dispatch(setSuccessOrderChangeMsg(false));
      }, 2000);
    }
  }, [successOrderChange]);
  useEffect(() => {
    if (successOrderPickup) {
      setTimeout(() => {
        dispatch(setSuccessOrderPickupMsg(false));
      }, 2000);
    }
  }, [successOrderPickup]);
  return (
    <>
      <Box
        bg="white"
        data-testid="PageHeader"
        py={23}
        px={64}
        sx={{
          position: "fixed",
          width: "100%",
          height: "100%",
          mb: 80,
        }}
      >
        <Flex>
          <Heading
            sx={{
              height: 100,
            }}
          >
            {t("ManageOrders.heading")}
          </Heading>
          {successOrderChange ? (
            <Alert
              ml={89}
              sx={{
                bg: "fern_50",
                height: "56px",
                width: "361px",
                fontSize: "subText",
                fontWeight: "weight_400",
                color: "fern_850",
              }}
            >
              <>
              <Box mr={20}>
                <SuccessIcon
                  style={{ marginRight: "20px" }}
                  color={"fern_500"}
                />
              </Box>
              {t("ManageOrders.order_placed")}
              {TagManager.dataLayer({ 
                dataLayer: {
                  event: "click_event",
                  Page_title: "Order Management | Order Change - Order placed successfully",
                  Action: "Change Order Successfull"
                } 
              })}
              <Close
                style={{ cursor: "pointer" }}
                ml="auto"
                mr={-2}
                onClick={() => dispatch(setSuccessOrderChangeMsg(false))}
                data-testid="OtpPage-error-alert-close-icon"
              />
              </>
            </Alert>
          ) : (
            ""
          )}
          {successOrderPickup ? (
            <>
            <Alert
              ml={89}
              sx={{
                bg: "fern_50",
                height: "56px",
                width: "361px",
                fontSize: "subText",
                fontWeight: "weight_400",
                color: "fern_850",
              }}
            >
              <>
              <Box mr={20}>
                <SuccessIcon
                  style={{ marginRight: "20px" }}
                  color={"fern_500"}
                />
              </Box>
              {t("ManageOrders.pickup_placed")}
              {TagManager.dataLayer({ 
                dataLayer: {
                  event: "click_event",
                  Page_title: "Order Management | Order Pickup - Order placed successfully",
                  Action: "Pickup Order Successfull"
                } 
              })}
              <Close
                style={{ cursor: "pointer" }}
                ml="auto"
                mr={-2}
                onClick={() => dispatch(setSuccessOrderPickupMsg(false))}
                data-testid="OtpPage-error-alert-close-icon"
              />
              </>
            </Alert>
            </>
          ) : (
            ""
          )}
        </Flex>

        <Flex
          sx={{
            display: "block",
          }}
        >
          <Flex 
            onClick={() => {
              navigate(`/orders/change`);
              TagManager.dataLayer({ 
                dataLayer: {
                  event: "Click_event",
                  Page_title: "Order management | Start screen",
                  Action: "Order Change" 
                } 
              });
            }}
            sx={{
              width: 430,
              height: 142,
              border: "1px solid",
              borderColor: "shade_200",
              borderRadius: 16,
              padding: 40,
              backgroundColor: "white",
              cursor: "pointer",
            }}
          >
            <Order_ChangeIcon
              color={"order_secondary"}
              secondaryColor={"order_primary"}
            />
            <Flex
              ml={37}
              sx={{
                flexDirection: "column",
              }}
            >
              <Text
                mt={10}
                sx={{
                  fontSize: "medium",
                  fontFamily: "heading",
                  fontWeight: "heading",
                  lineHeight: "location_lable",
                  color: "black",
                }}
              >
                {t("ManageOrders.order_change")}
              </Text>
              <Text
                mt={12}
                sx={{
                  fontSize: "subText",
                  fontFamily: "body",
                  fontWeight: "weight_400",
                  lineHeight: "register_bag",
                  color: "shade_400",
                }}
              >
                {t("ManageOrders.description")}
              </Text>
            </Flex>
          </Flex>
          <Flex
            onClick={() => {
              navigate(`/orders/pickup`);
              TagManager.dataLayer({ 
                dataLayer: {
                  event: "Click_event",
                  Page_title: "Order management | Start screen",
                  Action: "Order Pickup"
                } 
              })
            }}
            ml={40}
            sx={{
              width: 434,
              height: 142,
              border: "1px solid",
              borderColor: "shade_200",
              borderRadius: 16,
              padding: 40,
              backgroundColor: "white",
              cursor: "pointer",
            }}
          >
            <OrderPickupIcon
              color={"order_primary"}
              secondaryColor={"order_secondary"}
            />
            <Flex
              ml={37}
              sx={{
                flexDirection: "column",
              }}
            >
              <Text
                mt={10}
                sx={{
                  fontSize: "medium",
                  fontFamily: "heading",
                  fontWeight: "heading",
                  lineHeight: "location_lable",
                  color: "black",
                }}
              >
                {t("ManageOrders.order_pickup")}
              </Text>
              <Text
                mt={12}
                sx={{
                  fontSize: "subText",
                  fontFamily: "body",
                  fontWeight: "weight_400",
                  lineHeight: "register_bag",
                  color: "shade_400",
                }}
              >
                {t("ManageOrders.pickup_description")}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          sx={{
            display: "block",
          }}
          mt={35}
        >
          <Flex 
            onClick={() => {
              navigate(`/orders/list`);
              TagManager.dataLayer({ 
                dataLayer: {
                  event: "Click_event",
                  Page_title: "Order management | Start screen",
                  Action: "Overview orders" 
                } 
              });
            }}
            sx={{
              width: 430,
              height: 142,
              border: "1px solid",
              borderColor: "shade_200",
              borderRadius: 16,
              padding: 40,
              alignItems:"center",
              backgroundColor: "white",
              cursor: "pointer",
            }}
          >
            <ViewList
              color={"order_primary"}
            />
            <Flex
              ml={37}
              sx={{
                flexDirection: "column",
              }}
            >
              <Text
                mt={10}
                sx={{
                  fontSize: "medium",
                  fontFamily: "heading",
                  fontWeight: "heading",
                  lineHeight: "location_lable",
                  color: "black",
                }}
              >
                {t("ManageOrders.overview_orders")}
              </Text>
              <Text
                mt={12}
                sx={{
                  fontSize: "subText",
                  fontFamily: "body",
                  fontWeight: "weight_400",
                  lineHeight: "register_bag",
                  color: "shade_400",
                }}
              >
                {t("ManageOrders.overview_description")}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </>
  );
}
