/** @jsxImportSource theme-ui */

import { useState, useEffect } from "react";
import {
  Flex,
  Box,
  Label,
  Text,
  Alert,
  Input,
  Button,
  Heading,
  Card,
  Close,
} from "theme-ui";
import { ErrorIcon } from "@brinks/common/Icons/Components/error";
import { useTranslation } from "react-i18next";

import useTheme from "../../Hooks/useTheme";
import Logo from "@brinks/common/Icons/Logo";
import { Api } from "@brinks/common/api/auth";
import SuccessPage from "./SuccessPage";
import LeftSidePage from "./LeftSidePage";
import LangaugeSelector from "./LanguageSelector";
import TagManager from "react-gtm-module";
import { useSearchParams } from "react-router-dom";
import { REACT_APP_NATIVE_APP_BASE_URL } from "@brinks/common/utils/Config";
import { useOktaAuth } from "@okta/okta-react";

function ForgotPassword() {
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        Page_title: "User registration | Forgot password",
      },
    });
  }, []);

  const [isEmailEmpty, setIsEmailEmpty] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [email, setEmail] = useState("");
  const [showSuccessPage, setShowSuccessPage] = useState(false);
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { oktaAuth } = useOktaAuth();

  const themeType = useTheme();

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!email) {
      setIsEmailEmpty(true);
      return;
    }

    const pattern = new RegExp(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    if (!pattern.test(email)) {
      setIsEmailValid(true);
      return;
    }

    setIsEmailEmpty(false);
    setIsEmailValid(false);

    oktaAuth
      .forgotPassword({
        username: email,
        factorType: "EMAIL",
      })
      .then(() => {
        setLoading(false);
        setShowSuccessPage(true);
      })
      .catch((error) => {
        setIsError(true);
        setLoading(false);
      });
  };

  return (
    <Flex
      data-testid="SetPassword-wrapper"
      sx={{
        alignItems: "center",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <LeftSidePage />
      <Flex
        data-testid="SetPassword-wrapper-lang"
        bg={"white"}
        sx={{
          width: ["100%", "60%"],
          height: "100vh",
          alignItems: "center",
          flexDirection: "column",
          margin: "0 auto",
          overflowY: "scroll",
        }}
      >
        <Flex sx={{ display: ["block", "none"], mt: [10, 50] }}>
          <Logo themeType={themeType} />
        </Flex>
        {isError && (
          <Alert
            data-testid="SetPassword-error-alert"
            sx={{ mt: [50, 0], mx: [16, 0] }}
          >
            {t("something_went_wrong")}
            {TagManager.dataLayer({
              dataLayer: {
                event: "Click_event",
                Page_title: "User registration | Choose password",
                Action: "Error message: not valid or not the same password",
              },
            })}
            <Close
              data-testid="setPassword-close-icon"
              ml="auto"
              mr={-2}
              onClick={() => setIsError(false)}
            />
          </Alert>
        )}
        {showSuccessPage ? (
          <SuccessPage inResetFlow={false} />
        ) : (
          <Card
            sx={{
              width: ["100%", "50%"],
              px: ["16px", 0],
              mt: [10, 200],
            }}
            data-testid="setPassword-card"
          >
            <Box
              data-testid="setPassword-card-heading"
              mt={25}
              sx={{
                flexDirection: "column",
              }}
            >
              <Heading
                data-testid="setPassword-card-heading-text"
                color="royalBlue_700"
              >
                {t("Registration.forgot_password_label")}
              </Heading>
              <Box
                sx={{
                  py: 24,
                  color: "shade_400",
                  fontWeight: "weight_400",
                }}
              >
                {t("Registration.forgot_password_discription")}
              </Box>
              <Box data-testid="setPassword-card-input-wrapper">
                <Label
                  data-testid="setPassword-card-input-label"
                  color="shade_600"
                  sx={{ lineHeight: "27.2px", mb: 3 }}
                >
                  {t("Registration.email_address")}
                </Label>

                <Input
                  data-testid="setPassword-card-input-value"
                  placeholder={t("Registration.email_address")}
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  required
                />
              </Box>
              {isEmailEmpty && (
                <Flex pt={2} data-testid="OtpPage-card-error-flex">
                  <>
                    <ErrorIcon
                      color={"raspberry_500"}
                      data-testid="OtpPage-card-error-icon"
                    />
                    <Text
                      data-testid="OtpPage-card-invalid-code"
                      pl={2}
                      color="raspberry_500"
                      sx={{
                        fontSize: "subText",
                        fontWeight: "weight_400",
                      }}
                    >
                      {t("Registration.forgot_email_error")}
                    </Text>
                  </>
                </Flex>
              )}
              {isEmailValid && (
                <Flex pt={2} data-testid="OtpPage-card-error-flex">
                  <>
                    <ErrorIcon
                      color={"raspberry_500"}
                      data-testid="OtpPage-card-error-icon"
                    />
                    <Text
                      data-testid="OtpPage-card-invalid-code"
                      pl={2}
                      color="raspberry_500"
                      sx={{
                        fontSize: "subText",
                        fontWeight: "weight_400",
                      }}
                    >
                      {t("Registration.incorrect_email")}
                    </Text>
                  </>
                </Flex>
              )}
              <Button
                data-testid="setPassword-card-submit-btn"
                mt={50}
                onClick={handleSubmit}
                value="Submit"
                disabled={loading}
                variant={`${loading ? "gray" : "primary"}`}
                sx={{
                  boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
                  height: 49,
                  width: ["100%", "169px"],
                  borderRadius: "40px",
                  cursor: "pointer",
                }}
              >
                {t("Registration.send_link")}
              </Button>
            </Box>
          </Card>
        )}
      </Flex>
    </Flex>
  );
}

export default ForgotPassword;
