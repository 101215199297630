import TagManager from "react-gtm-module";
import Select, { StylesConfig } from "react-select";
import { useTranslation } from "react-i18next";
import { useState, useEffect, CSSProperties } from "react";
import { Case } from "@brinks/common/api/Api";
import { useAppSelector } from "@brinks/common/hooks/hooks";
import Loader from "../../../Shared/Loader";
import { Flex, Input } from "theme-ui";
import { getStoreLocations } from "@brinks/common/reducers/storeLocationSlice";
import { useAppDispatch } from "@brinks/common/hooks/hooks";
import { useOktaAuth } from "@okta/okta-react";
import { getCurrentUserForApi } from "@brinks/common/utils";

const customControlStyles: CSSProperties = {
  height: "50px",
  width: "300px",
  paddingLeft: "12px",
  margin: "16px 12px 12px 0",
  boxShadow: "0px 4px 8px rgb(0 0 0 / 10%)",
};

const customStyles: StylesConfig<any> = {
  control: (provided, state) => {
    return { ...provided, ...customControlStyles };
  },
  dropdownIndicator: (provided, state) => ({
    paddingTop: "5px",
    color: "shade_400",
    paddingRight: "20px",
  }),
};

interface Options {
  label: string;
  value: string;
}

interface SupportListMerchantSelectorProps {
  cases: Case[];
  onChange: (status: string | null) => void;
}

export const SupportListMerchantSelector = ({
  cases,
  onChange,
}: SupportListMerchantSelectorProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { oktaAuth } = useOktaAuth();
  const token: any = oktaAuth.getAccessToken();
  const userRole = getCurrentUserForApi(token);
  const { merchants, loadingMerchants } = useAppSelector(
    (state) => state.merchantsDetailSlice
  );
  const [isLoading, setIsLoading] = useState(true);

  const [options, setOptions] = useState<Options[]>([]);

  const { storeLocations, loading } = useAppSelector(
    (state) => state.storeLocationSlice
  );

  useEffect(() => {
    dispatch(getStoreLocations({ userType: userRole })).then(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!storeLocations) return;
    const merchantMap = new Map();

    storeLocations
      .filter((storeLocation) => storeLocation.merchant)
      .forEach((storeLocation) => {
        const merchantId = storeLocation?.merchant?.id;
        if (!merchantMap.has(merchantId)) {
          merchantMap.set(merchantId, storeLocation.merchant);
        }
      });
    const uniqueMerchants = Array.from(merchantMap.values());

    const merchantOptions = uniqueMerchants.map((obj: any) => {
      return { ...obj, label: obj.name, value: obj.id };
    });

    setOptions([
      { value: "ALL", label: t("UserLocationFilter.all") },
      ...merchantOptions,
    ]);
  }, [storeLocations]);

  const handleChange = (e: any) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "Click_event",
        Action: "Merchant " + e.value,
        Page_title: "User management | User overview",
      },
    });

    const option = options.find((option) => option.value === e.value);

    if (option) {
      onChange(option.value === "ALL" ? null : option.value);
    }
  };

  if (loadingMerchants || options.length === 0 || isLoading) {
    return (
      <Flex sx={{ alignItems: "center" }}>
        <Select
          placeholder={`Loading...`}
          options={[]}
          styles={customStyles}
          isDisabled
        />
      </Flex>
    );
  }

  return options.length === 2 && options.find((x) => x.value !== "ALL") ? (
    <Flex
      sx={{
        alignItems: "center",
      }}
    >
      <Input
        type={"text"}
        placeholder={`${t(
          "CasesFilter.merchant_placeholder"
        )}: ${options[1].label.toUpperCase()}`}
        value={options[1].label}
        sx={{
          bg: "white",
          border: "1px solid",
          borderColor: "shade_200",
          borderRadius: 8,
          textAlign: "left",
          color: "shade_400",
          width: 600,
          mt: 1,
          mr: 1,
        }}
      />
    </Flex>
  ) : (
    <Select
      options={options}
      styles={customStyles}
      onChange={handleChange}
      data-testid="advanceFilter-UserLocationMerchant-select"
      placeholder={`${t(
        "CasesFilter.merchant_placeholder"
      )}: ${options[0].label.toUpperCase()}`}
    />
  );
};
