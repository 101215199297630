// ---------------------------------------------------------
// EXTERNAL IMPORTS
// ---------------------------------------------------------
import TagManager from "react-gtm-module";
import Select, { StylesConfig } from "react-select";

import { useTranslation } from "react-i18next";
import { useState, useEffect, CSSProperties, useMemo } from "react";

// ---------------------------------------------------------
// INTERNAL IMPORTS
// ---------------------------------------------------------
import { useAppDispatch, useAppSelector } from "@brinks/common/hooks/hooks";
import {
  MerchantsAndLocations,
} from "@brinks/common/api/Api";
import {
  setLocationListDisabled,
  setPickupLocation,
  setselectedOrderChangeMerchant,
} from "@brinks/common/reducers/orderDetailsSlice";
import {
  getMerchants,
  setCurrentMerchant,
} from "@brinks/common/reducers/merchantsDetailSlice";
import { useOktaAuth } from "@okta/okta-react";
import { getCurrentUserForApi } from "@brinks/common/utils";
import { Input } from "theme-ui";

// ---------------------------------------------------------
// STYLE
// ---------------------------------------------------------
const customControlStyles: CSSProperties = {
  height: "50px",
  width: "300px",
  paddingLeft: "12px",
  margin: "16px 12px 12px 0",
  boxShadow: "0px 4px 8px rgb(0 0 0 / 10%)",
};

const customStyles: StylesConfig<any> = {
  control: (provided, state) => {
    return { ...provided, ...customControlStyles };
  },
  dropdownIndicator: (provided, state) => ({
    paddingTop: "5px",
    color: "shade_400",
    paddingRight: "20px",
  }),
};

// ---------------------------------------------------------
// TYPES
// ---------------------------------------------------------
interface Options {
  label: string;
  value: string;
}

interface OrderChangeMerchantSelectorProps {
  value: null | MerchantsAndLocations;
}

export const OrderChangeMerchantSelector = ({
  value,
}: OrderChangeMerchantSelectorProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // TESTE USER
  const { oktaAuth } = useOktaAuth();
  const token: any = oktaAuth.getAccessToken();
  const userRole = getCurrentUserForApi(token);
  const userType = userRole;

  const { merchants, loadingMerchants } = useAppSelector(
    (state) => state.merchantsDetailSlice
  );

  const { locationListDisabled } = useAppSelector(
    (state) => state.orderDetailsSlice
  );

  const { storeLocations, loading } = useAppSelector(
    (state) => state.storeLocationSlice
  );

  const [options, setOptions] = useState<Options[]>([]);

  useEffect(() => {
    if (!storeLocations) return;
    const merchantMap = new Map();
  
    storeLocations
      .filter(storeLocation => storeLocation.merchant)
      .forEach(storeLocation => {
        const merchantId = storeLocation?.merchant?.id;
        
        if (!merchantMap.has(merchantId)) {
          merchantMap.set(merchantId, storeLocation.merchant);
        }
      });
  
    const uniqueMerchants = Array.from(merchantMap.values());

    const merchantOptions = uniqueMerchants.map((obj: any) => {
      return { ...obj, label: obj.name, value: obj.id };
    });
    setOptions([...merchantOptions]);
  
  }, [storeLocations]);


  const handleChange = (option: Options) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "Click_event",
        Action: "Merchant " + option.value,
        Page_title: "Orders management | Order change",
      },
    });

    if (option) {
      dispatch(setPickupLocation(null));
      dispatch(setLocationListDisabled(false));
      dispatch(setCurrentMerchant(option));
      dispatch(setselectedOrderChangeMerchant(option));
    }
  };

  const valueSelected = useMemo(() => {
    if (value === null && options && options.length === 1) {
      handleChange(options[0]);
      return options[0];
    }

    if (!value && !locationListDisabled) {
      return { value: "ALL", label: t("UserLocationFilter.all") };
    }

    return options.find((option) => {
      return option.value === value?.id;
    });
  }, [value, options]);

  return options.length === 1 ? (
    <Input
      contentEditable={false}
      placeholder={`${t(
        "ManageOrders.merchant_placeholder"
      )}: ${options[0].label.toUpperCase()}`}
      value={options[0].label}
    />
  ) : (
    <Select
      options={options}
      value={valueSelected}
      styles={customStyles}
      onChange={handleChange}
      data-testid="advanceFilter-OrderChangeMerchantSelector-select"
      placeholder={`${t("ManageOrders.merchant_placeholder")}:`}
    />
  );
};
