// ---------------------------------------------------------
// EXTERNAL IMPORTS
// ---------------------------------------------------------
import TagManager from "react-gtm-module";
import Select, { StylesConfig } from "react-select";

import { useTranslation } from "react-i18next";
import { CSSProperties, useMemo } from "react";

// ---------------------------------------------------------
// STYLE
// ---------------------------------------------------------
const customControlStyles: CSSProperties = {
  height: "50px",
  width: "300px",
  paddingLeft: "12px",
  margin: "16px 12px 12px 0",
  boxShadow: "0px 4px 8px rgb(0 0 0 / 10%)",
};

const customStyles: StylesConfig<any> = {
  control: (provided, state) => {
    return { ...provided, ...customControlStyles };
  },
  dropdownIndicator: (provided, state) => ({
    paddingTop:'5px',
    color:'shade_400',
    paddingRight:"20px",
  }),
};

// ---------------------------------------------------------
// TYPES
// ---------------------------------------------------------
interface Option {
  label: string;
  value: string;
}

interface SupportListStatusSelectorProps {
  onChange: (status: string | null) => void;
}

export const SupportListStatusSelector = ({ 
  onChange 
}: SupportListStatusSelectorProps) => {
  const { t } = useTranslation();

  const options = useMemo(() => {
    return [ 
      { value: 'ALL', label: t("UserLocationFilter.all")},
      { value: "New", label: "New" },
      { value: "Sent", label: "Sent" },
      { value: "Closed", label: "Closed" },
      { value: "In progress", label: "In progress" },
    ];
  }, [])


  const handleChange = (optionSelected: Option) => {
    TagManager.dataLayer({ 
      dataLayer: {
        event: "Click_event",
        Action: "Merchant " + optionSelected.value,
        Page_title: "User management | User overview",
      }
    });
    
    onChange(
      optionSelected.value === "ALL" 
        ? null 
        : optionSelected.value
    );
  };

  return (
    <Select
      options={options}
      styles={customStyles}
      onChange={handleChange}
      data-testid="advanceFilter-CasesStatusSelector-select"
      placeholder={`${t("CasesFilter.status_search_placeholder")}: ${options[0].label.toUpperCase()}`}
    />
  );
}
