import { Box, Text, Flex } from "theme-ui";

import Label from "./Label";
import FieldText from "./FieldText";
import Heading from "./Heading";
import { TransactionDetails as TransDetail } from "@brinks/common/api/Api";
import { useTranslation } from "react-i18next";
import { localizeAmount } from "@brinks/common/utils";
import { useAppSelector } from "@brinks/common/hooks/hooks";
interface Props {
  transactionDetails: TransDetail | null;
}
export default function TransactionDetails({ transactionDetails }: Props) {
   const { t } = useTranslation();
   const amount: any = transactionDetails?.grossAmount;
   const { currentCurrency, currentCountry } = useAppSelector(
     (state) => state.dashboardSlice
   );

  return (
    <Box data-testid="TransactionDetails-box">
      <Heading data-testid="TransactionDetails-heading">
        {t("TransactionDetails.transaction_details")}
      </Heading>

      <Flex
        mt={39}
        sx={{
          flexDirection: "column",
        }}
        data-testid="TransactionDetails-amount"
      >
        <Label data-testid="TransactionDetails-amount-label"> {t("TransactionDetails.amount")}</Label>
        <Text
          sx={{
            fontSize: "large",
            color: "text",
            fontWeight: "weight_700",
            lineHeight: "35.2px",
          }}
          data-testid="TransactionDetails-amount-value"
        >
          {localizeAmount(amount, currentCurrency, currentCountry)}
        </Text>
      </Flex>
      <Flex
        mt={39}
        sx={{
          justifyContent: "space-between",
        }}
        data-testid="TransactionDetails-type"
      >
        <Flex
          sx={{
            flexDirection: "column",
            justifyContent: "space-between",
            flexGrow: 1,
          }}
          data-testid="TransactionDetails-type-flex"
        >
          <Label data-testid="TransactionDetails-type-label">
             {t("TransactionDetails.transaction_type")} 
          </Label>
          <FieldText data-testid="TransactionDetails-type-cash">
            {transactionDetails?.type}
          </FieldText>
        </Flex>
        <Flex
          sx={{
            flexDirection: "column",
            flexGrow: 1,
          }}
          data-testid="TransactionDetails-payment-flex"
        >
          <Label data-testid="TransactionDetails-payment-flex-label">
            {t("TransactionDetails.payment_method")} 
          </Label>
          <FieldText data-testid="TransactionDetails-payment-flex-label-value">
            {transactionDetails?.paymentMethod}
          </FieldText>
        </Flex>
      </Flex>

      {transactionDetails?.type === "CASH" ||
      transactionDetails?.type === "CLICKS" ? (
        <>
          <Flex
            mt={39}
            sx={{
              justifyContent: "space-between",
              flexDirection: "column",
            }}
            data-testid="TransactionDetails-order"
          >
            <Label data-testid="TransactionDetails-order-id"> {t("TransactionDetails.order_id")} </Label>
            <FieldText data-testid="TransactionDetails-order-id-value">
              {transactionDetails?.orderId || "N/A"}
            </FieldText>
          </Flex>
          <Flex
            mt={39}
            sx={{
              justifyContent: "space-between",
              flexDirection: "column",
            }}
            data-testid="TransactionDetails-Description"
          >
            <Label data-testid="TransactionDetails-order-id">{t("TransactionDetails.status")} </Label>
            <FieldText data-testid="TransactionDetails-order-id-value">
              {transactionDetails?.paymentStatus}
            </FieldText>
          </Flex>
        </>
      ) : (
        <Flex
          mt={39}
          sx={{
            justifyContent: "space-between",
            flexDirection: "column",
          }}
          data-testid="TransactionDetails-Description"
        >
          <Label data-testid="TransactionDetails-order-id">{t("TransactionDetails.status")}</Label>
          <FieldText data-testid="TransactionDetails-order-id-value">
            {transactionDetails?.paymentStatus}
          </FieldText>
        </Flex>
      )}

      <Flex
        mt={39}
        sx={{
          justifyContent: "space-between",
          flexDirection: "column",
        }}
        data-testid="TransactionDetails-Description"
      >
        <Label data-testid="TransactionDetails-Description-label">
          {t("TransactionDetails.description")}
        </Label>
        <FieldText data-testid="TransactionDetails-Description-value">
          {transactionDetails?.remittanceInformation || "N/A"}
        </FieldText>
      </Flex>
    </Box>
  );
}
