/** @jsxImportSource theme-ui */
import { Flex } from "theme-ui";

import { ReactNode } from "react";
interface Props {
  children: ReactNode;
}
export default function PageHeader({ children }: Props) {
  return (
    <>
      <Flex
        bg="white"
        data-testid="PageHeader"
        py={23}
        px={32}
        sx={{
          borderBottom: `1px solid #C3CAD5`,
          position: "fixed",
          width: "100%",
          mb: 80,
          zIndex: 99,
          
        }}
      >
        {children}
      </Flex>
    </>
  );
}
