import { useAppSelector } from "@brinks/common/hooks/hooks";
import { useTranslation } from "react-i18next";
import { Text, Flex, Divider } from "theme-ui";
import DinominationSummaryRow from "./DinomiantionSummaryRow";

export default function DenominationSummary() {
  const { t } = useTranslation();
  const {
    totalWithCurrency,
    totalOfNotesWithCurrency,
    totalOfCoinsWithCurrency,
    declaredNoteValues,
    declaredCoinValues,
    totalOfCoins,
    totalOfNotes,
    locationBusinessRules,
  } = useAppSelector((state) => state.orderDetailsSlice);

  const { businessRulesMoneyOrdersSkus } = useAppSelector(
    (state) => state.businessRulesMoneyOrdersSkusSlice
  );


  return (
    <>
      <Flex
        mt={32}
        bg="white"
        sx={{
          p: 35,
          borderBottom: "1px solid",
          borderColor: "borderColor",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: 8,
          flexDirection: "column",
        }}
        data-testid="LocationList"
      >
        {totalOfNotes > 0 ? (
          <>
            <Flex
              mt={20}
              sx={{
                fontSize: "medium",
                fontFamily: "heading",
                fontWeight: "heading",
                lineHeight: "location_lable",
              }}
            >
              {t("ManageOrders.notes")}
            </Flex>
            <Flex
              my={24}
              sx={{
                width: 800,
                justifyContent: "space-between",
              }}
            >
              <Text
                sx={{
                  color: "shade_800",
                }}
              >
                {t("ManageSealBags.denomination")}
              </Text>
              <Text
                sx={{
                  color: "shade_800",
                }}
              >
                {t("ManageOrders.notes_per_bundle")}
              </Text>
              <Text
                sx={{
                  color: "shade_800",
                  width: 150,
                }}
              >
                {t("ManageOrders.no_bundles")}
              </Text>
            </Flex>
            {declaredNoteValues.length > 0 &&
              declaredNoteValues?.map((el: any, index: any) =>
                el.denominationQuantity > 0 ? (
                  <DinominationSummaryRow key={el.skuId} data={el} />
                ) : (
                  ""
                )
              )}
          </>
        ) : (
          ""
        )}

        {totalOfCoins > 0 ? (
          <>
            <Flex
              mt={45}
              sx={{
                fontSize: "medium",
                fontFamily: "heading",
                fontWeight: "heading",
                lineHeight: "location_lable",
              }}
            >
              {t("ManageOrders.coins")}
            </Flex>
            <Flex
              my={24}
              sx={{
                width: 800,
                justifyContent: "space-between",
              }}
            >
              <Text
                sx={{
                  color: "shade_800",
                }}
              >
                {t("ManageSealBags.denomination")}
              </Text>
              <Text
                mr={30}
                sx={{
                  color: "shade_800",
                }}
              >
                {t("ManageOrders.coins_per_role")}
              </Text>
              <Text
                sx={{
                  color: "shade_800",
                  width: 150,
                }}
              >
                {t("ManageOrders.no_roles")}
              </Text>
            </Flex>
            {declaredCoinValues.length > 0 &&
              declaredCoinValues?.map((el: any, index: any) =>
                el.denominationQuantity > 0 ? (
                  <DinominationSummaryRow key={el.skuId} data={el} />
                ) : (
                  ""
                )
              )}
          </>
        ) : (
          ""
        )}

        <Divider sx={{ width: "100%" }} color="divider" mt={20} />
        <Flex
          mt={24}
          sx={{
            width: 375,
            justifyContent: "space-between",
            fontWeight: "weight_400",
            fontSize: "body",
          }}
        >
          <Text
            sx={{
              fontWeight: "weight_400",
              fontSize: "body",
            }}
          >
            {t("ManageOrders.notes")}
          </Text>
          <Text
            sx={{
              width: 150,
              fontWeight: "weight_400",
              fontSize: "body",
            }}
          >
            {totalOfNotesWithCurrency}
          </Text>
        </Flex>
        <Flex
          mt={22}
          sx={{
            width: 375,
            justifyContent: "space-between",
          }}
        >
          <Text
            sx={{
              color: "shade_800",
              fontWeight: "weight_400",
              fontSize: "body",
            }}
          >
            {t("ManageOrders.coins")}
          </Text>
          <Text
            sx={{
              width: 150,
              fontWeight: "weight_400",
              fontSize: "body",
            }}
          >
            {totalOfCoinsWithCurrency}
          </Text>
        </Flex>
        <Flex
          mt={22}
          sx={{
            width: 375,
            justifyContent: "space-between",
          }}
        >
          <Text
            sx={{
              color: "shade_800",
              fontWeight: "body",
              fontSize: "body",
              lineHeight: "label",
            }}
          >
            {t("ManageSealBags.grand_total")}
          </Text>
          <Text
            sx={{
              width: 150,
              fontSize: "heading_4",
              fontWeight: "heading",
            }}
          >
            {totalWithCurrency}
          </Text>
        </Flex>
      </Flex>
    </>
  );
}
