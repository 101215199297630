import { kFormatter, range } from "@brinks/common/utils";
import { CallbackArgs } from "victory-core";
import {
  VictoryChart,
  VictoryAxis,
  VictoryBar,
  VictoryGroup,
  VictoryStack,
} from "victory";
import { colors as style_color } from "@brinks/common/colors";
import { useAppSelector } from "@brinks/common/hooks/hooks";
import { useThemeUI } from "theme-ui";

interface DashboardBarChartProps {
  labels: string[];
  datasets: { data: number[]; color: string }[];
  width: any;
}

export default function DashboardBarChart({
  labels,
  datasets,
  width,
}: DashboardBarChartProps) {
  const { selectedTransactionFilterType } = useAppSelector(
    (state) => state.dashboardSlice
  );
  const colors: string[] = [];
  const context = useThemeUI();

  const { colorMode } = context;
  const data = datasets.map((dataset, i) => {
    if (colorMode === "brinks") {
      if (dataset.color === "#0A408F") {
        colors.push("#0A4A8E");
      }
      if (dataset.color === "#11CCA8") {
        colors.push("#2C9942");
      }
      if (dataset.color === "#0F61D8") {
        colors.push("#97CAEB");
      }
    } else {
      colors.push(dataset.color);
    }
    return dataset.data.map((d, index) => {
      return { x: labels[index], y: d };
    });
  });
  if (data.length == 0) {
    return null;
  }
  const xAxisMax = 7;
  const sumValue: number[] = [];
  data.forEach((dataset, index) => {
    dataset.forEach((d, i) => {
      sumValue[i] = (sumValue[i] || 0) + d.y;
    });
  });
  const yAxisMax = Math.ceil(Math.max.apply(Math, sumValue));

  const getCornerRadius = (datasetElement: CallbackArgs, index: number) => {
    const datasetIndex = parseInt(datasetElement.index.toString());

    const array = range(index, data.length, 1);
    if (
      datasetElement.datum?.y !== 0 &&
      array.map((n) => data[n][datasetIndex]).filter((d) => d?.y !== 0)
        .length === 1
    ) {
      return 8;
    } else {
      return 0;
    }
  };

  return (
    <VictoryChart width={width} padding={{ left: 100, right: 80 }} domainPadding={{ x : [50, 50] }} >
      <VictoryGroup>
        <VictoryStack>
          {data.map((dataset, index) => {
            return (
              <VictoryBar
                key={index}
                data={dataset}
                cornerRadius={{
                  topLeft: (t) => getCornerRadius(t, index),
                  topRight: (t) => getCornerRadius(t, index),
                }}
                style={{
                  data: {
                    fill: colors[index],
                    width: 50,
                  },
                }}
              />
            );
          })}
        </VictoryStack>
      </VictoryGroup>
      <VictoryAxis
        style={{
          axis: { stroke: style_color.beaver, strokeWidth: 1 },
          tickLabels: {
            fill: style_color.beaver,
          },
        }}
      />
      <VictoryAxis
        dependentAxis
        tickValues={[0, yAxisMax / 2, yAxisMax]}
        tickFormat={(t) =>
          selectedTransactionFilterType.action == "count"
            ? `${Math.ceil(t)}`
            : `€${kFormatter(t)}`
        }
        style={{
          grid: { stroke: style_color.beaver, strokeDasharray: "4,5" },
          axis: { strokeWidth: 0 },
          tickLabels: {
            fill: style_color.beaver,
          },
        }}
      />
    </VictoryChart>
  );
}
