import { formatAmount } from "@brinks/common/utils";
import { Flex, Text, Divider } from "theme-ui";

interface Props {
  data: any;
}
export default function DenominationRow({ data }: Props) {

  return (
    <>
      <Divider color="divider" mt={10} />
      <Flex
        sx={{
          width: 800,
          justifyContent: "space-between",
        }}
      >
        <Text
          sx={{
            color: "shade_500",
            fontSize: "body",
            fontWeight: "weight_400",
            width: 70,
          }}
        >
          {formatAmount(data.faceValue, data.currency)}
        </Text>
          <Text
            sx={{
              color: "shade_500",
              fontSize: "body",
              fontWeight: "weight_400",
              width: 100,
            }}
          >
            {data.currencyType === "Coin" ? data.sku : data.increments}
          </Text>
        <Text
          sx={{
            color: "shade_500",
            fontSize: "body",
            fontWeight: "weight_400",
            width: 150,
          }}
        >
          {data.currencyType === "Note" ? data.denominationQuantity : data.denominationQuantity*data.increments}
        </Text>
      </Flex>
    </>
  );
}
