/** @jsxImportSource theme-ui */

import React, { useEffect, useState } from "react";
import {
  Flex,
  Label,
  Text,
  Alert,
  Input,
  Button,
  Heading,
  Card,
  Close,
} from "theme-ui";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReSendIcon } from "@brinks/common/Icons/Components/resendIcon";
import { ErrorIcon } from "@brinks/common/Icons/Components/error";

import useTheme from "../../Hooks/useTheme";
import Logo from "@brinks/common/Icons/Logo";
import { Api } from "@brinks/common/api/auth";
import LeftSidePage from "./LeftSidePage";
import LangaugeSelector from "./LanguageSelector";
import {
  REACT_APP_NATIVE_APP_BASE_URL,
} from "@brinks/common/utils/Config";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";

function Activation() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [securityCode, setSecurityCode] = useState("");
  const [isTokenError, setIsTokenError] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSmsError, setIsSmsError] = useState(false);
  const [counter, setCounter] = React.useState(60);
  const [userId, setUserId] = useState(null);
  const [factorId, setFactorId] = useState(null);
  const [isEmpty, setIsEmpty] = useState(false);
  const [loading, setLoading] = useState(false)
  const themeType = useTheme();

  useEffect(() => {
    const obj = {
      token: searchParams.get("code"),
    };
    async function fetchToken() {
      try {
        const url: any = `${REACT_APP_NATIVE_APP_BASE_URL}/api/v1/authn`;
        const res = await fetch(url, {
          method: "POST",
          body: JSON.stringify(obj),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        const resp = await res.json();
        if (resp?.errorId) {
          setIsTokenError(true);
          return
        }

        setUserId(resp?._embedded.user.id);
        localStorage.setItem("userId", resp?._embedded.user.id);
        const smsObj = {
          userId: resp?._embedded.user.id,
        };
        if (smsObj.userId) {
          const smsResp: any = await new Api().auth.enrollSms(smsObj);
          setFactorId(smsResp?.data.factorId);
        }
      } catch (error: any) {
        setIsTokenError(true);
      }
    }
    fetchToken();
  }, []);

  useEffect(() => {
    const timer: any =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const resendCode = async () => {
    setCounter(60);
    const smsObj: any = {
      userId: userId,
    };
    if (userId) {
      await new Api().auth.enrollSms(smsObj);
    }
  };

  const handleVerifySms = async () => {
    setLoading(true)
    if (!securityCode) {
      setIsEmpty(true)
      setLoading(false)
      return;
    }
    setIsEmpty(false)
    const paylaod: any = {
      userId: userId,
      factorId: factorId,
      securityCode: securityCode,
    };
    try {
      const res = await new Api().auth.activateSms(paylaod);
      navigate(`/set-password`);
      setIsSmsError(false);
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      setIsSmsError(true);
    }
  };

  return (
    <Flex
      data-testid="activation"
      sx={{
        alignItems: "center",
        flexDirection: ["column", "row"],
        height: "100%",
        overflow: "hidden",
      }}
    >
      <LeftSidePage />

      <Flex
        data-testid="activation-right"
        bg={"white"}
        sx={{
          width: ["100%", "60%"],
          height: "100vh",
          alignItems: "center",
          flexDirection: "column",
          margin: "0 auto",
          overflowY: 'scroll'
        }}
      >
        <Flex
          sx={{ alignSelf: "flex-end" }}
          mr={31}
          mt={30}
          data-testid="activation-lang"
        >
          <LangaugeSelector />
        </Flex>
        <Flex sx={{ display: ["block", "none"], mt: 50 }}>
          <Logo themeType={themeType} />
        </Flex>
        {isTokenError ? (
          <Alert
            data-testid="activation-error-alert"
            sx={{ mt: [70, 0], mx: [16, 0] }}
          >
            {t("Registration.expired_msg")}
            <Close
              ml="auto"
              mr={-2}
              onClick={() => setIsTokenError(false)}
              data-testid="activation-error-alert-close-icon"
            />
          </Alert>
        ) : null}
        <Card
          data-testid="activation-card"
          sx={{
            width: ["100%", "50%"],
            px: ["16px", 0],
            mt: [50, 200],
          }}
        >
          <Flex
            data-testid="activation-card-wrapper"
            mt={25}
            sx={{
              flexDirection: "column",
            }}
          >
            <Heading data-testid="activation-card-heading">
              {t("Registration.enter_verification")}
            </Heading>
            <Text
              data-testid="activation-card-sms-text"
              mt={25}
              sx={{
                fontWeight: "weight_400",
                color: "shade_600",
              }}
            >
              {t("Registration.sms")}
            </Text>
            <Label
              data-testid="activation-card-label"
              mt={25}
              sx={{
                fontSize: "subText",
                color: "shade_800",
              }}
            >
              {t("Registration.code_lable")}
            </Label>
            <Input
              data-testid="activation-card-input"
              sx={{
                p: "17px",
                border: `1px solid borderColor`,
                borderRadius: "6px",
              }}
              mt={25}
              type="number"
              value={securityCode}
              autoFocus={true}
              onChange={(e) => {
                setSecurityCode(e.target.value);
              }}
              placeholder="Enter code"
              required
            />
            {isSmsError  || isEmpty && (
              <Flex pt={2} data-testid="activation-card-error-flex">
                <ErrorIcon
                  color={"raspberry_500"}
                  data-testid="activation-card-error-icon"
                />
                <Text
                  data-testid="activation-card-invalid-code"
                  pl={2}
                  color="raspberry_500"
                  sx={{
                    fontSize: "subText",
                    fontWeight: "weight_400",
                  }}
                >
                  {isEmpty ? t("Registration.enter_otp") : t("Registration.code_invalid")}
                </Text>
              </Flex>
            )}

            <Flex
              data-testid="activation-card-resend-code-flex"
              mt={25}
              sx={{
                justifyContent: "space-between",
                alignItems: ["flex-start", "center"],
                flexDirection: ["column-reverse", "row"],
              }}
            >
              {counter === 0 ? (
                <Flex
                  onClick={() => {
                    TagManager.dataLayer({
                      dataLayer: {
                        event: "Click_event",
                        Page_title: "Sign in | Enter verification code - SMS",
                        Action: "Resend code"
                      }
                    });
                    resendCode();
                  }}
                  sx={{ alignItems: "center" }}
                >
                  <ReSendIcon color={"primary"} />
                  <Text
                    ml={2}
                    color="royalBlue_500"
                    sx={{
                      fontWeight: "weight_400",
                      lineHeight: "27.2px",
                      textAlign: "center",
                    }}
                  >
                    {t("Registration.resend_code")}
                  </Text>
                </Flex>
              ) : (
                <Text
                  data-testid="activation-card-resend-code-flex-text"
                  color="shade_400"
                >
                  {t("Registration.resend_code_in")} {counter}
                </Text>
              )}
              <Button
                data-testid="activation-card-resend-code-flex-button"
                onClick={handleVerifySms}
                disabled={loading}
                variant={`${loading ? "gray" : "primary"}`}
                sx={{
                  boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
                  height: 49,
                  width: ["100%", "169px"],
                  mb: [20, 0],
                  borderRadius: "40px",
                  cursor: "pointer",
                }}
              >
                {t("confirm")}
              </Button>
            </Flex>
          </Flex>
        </Card>
      </Flex>
    </Flex>
  );
}

export default Activation;
