/** @jsxImportSource theme-ui */

import { useAppDispatch, useAppSelector } from "@brinks/common/hooks/hooks";
import React, { useEffect, useState } from "react";
import { verifyOTP, sendOtp } from "@brinks/common/reducers/authSlice";
import {
  Flex,
  Label,
  Text,
  Alert,
  Input,
  Button,
  Heading,
  Card,
  Close,
} from "theme-ui";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReSendIcon } from "@brinks/common/Icons/Components/resendIcon";
import { ErrorIcon } from "@brinks/common/Icons/Components/error";
import { useTranslation } from "react-i18next";
import useTheme from "../../../Hooks/useTheme";
import Logo from "@brinks/common/Icons/Logo";
import LangaugeSelector from "../LanguageSelector";
import pkceChallenge from "pkce-challenge";

import {
  REACT_APP_NATIVE_APP_CLIENT_ID,
  REACT_APP_NATIVE_APP_REDIRECT_URI,
  REACT_APP_NATIVE_APP_DISCOVERY_URI
} from "@brinks/common/utils/Config";
import TagManager from "react-gtm-module";
import { useOktaAuth } from "@okta/okta-react";
import config from "@brinks/common/config";

function OtpPage() {

  useEffect(() => {
    TagManager.dataLayer({ 
      dataLayer: {
        event: "page_view",
        Page_title: "User registration | Enter verification code",
      }
    });
  }, []);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [securityCode, setSecurityCode] = useState("");
  const [isTokenError, setIsTokenError] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSmsError, setIsSmsError] = useState(false);
  const [counter, setCounter] = React.useState(60);
  const [userId, setUserId] = useState(null);
  const { t } = useTranslation();
  const themeType = useTheme();
  const challenge = pkceChallenge();
  const { authState, oktaAuth } = useOktaAuth();
  const [loading,setLoading] = useState(false)

  // observing user login and token change
  React.useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      console.log("1st OKTA TOKEN STORAGE --> ", authState);
    } else {
      // getting info of user
      oktaAuth
        .getUser()
        .then((info) => {
          // you can pass user info and token from here to server side
        })
        .catch((err) => {
          console.error("error --->",err);
        });
    }
  }, [authState, oktaAuth]);

  const { isError, tokenData, verifyOTPData, isAuthenticated } =
    useAppSelector((state) => state.authSlice);
  useEffect(() => {
    const timer: any =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const resendCode = () => {
    setCounter(60);
    sendOtp(tokenData);
  };
  const getTokensFromSessionToken = (sessionToken: string) => {
    oktaAuth.token
      .getWithoutPrompt({
        responseType: ["token", "id_token", "refresh_token"], // or array of types
        sessionToken: sessionToken, // optional if the user has an existing Okta session
        redirectUri: config.oidc.redirectUri,
      })
      .then((resp) => {
        oktaAuth.tokenManager.setTokens(resp.tokens);
        setTimeout(() => {
          setLoading(false)
          window.history.replaceState(null, "null", "/");
          navigate("/");
          
        }, 2000);
      })
      .catch((error) => console.log("error ----> ", error));
  }

  useEffect(() => {
    if (isAuthenticated) {
      getTokensFromSessionToken(verifyOTPData?.sessionToken);
    }
  }, [verifyOTPData?.sessionToken]);

  useEffect(() => {
    if (verifyOTPData?.errorId) {
      setLoading(false)
      {
        TagManager.dataLayer({ 
          dataLayer: {
            event: "Click_event",
            Page_title: "User registration | Enter verification code",
            Action: "Error message: code invalid"
          }
        })
      }
    }
  }, [verifyOTPData?.errorId]);

  const handleVerifySms = async () => {
    if (!securityCode) {
      return;
    }
    const paylaod: any = {
      tokenData: tokenData,
      passCode: securityCode,
    };
    setLoading(true)
    dispatch(verifyOTP(paylaod));
  };

  return (
      <Flex
        data-testid="OtpPage-right"
        bg={"white"}
        sx={{
          width: ["100%", "60%"],
          height: "100vh",
          alignItems: "center",
          flexDirection: "column",
          margin: "0 auto",
        }}
      >
        <Flex
          sx={{ alignSelf: "flex-end" }}
          mr={31}
          mt={30}
          data-testid="OtpPage-lang"
        >
          <LangaugeSelector />
        </Flex>
        <Flex sx={{ display: ["block", "none"], mt: 50 }}>
          <Logo themeType={themeType} />
        </Flex>
        {/* {verifyOTPData?.errorId ? (
          <Alert
            data-testid="OtpPage-error-alert"
            sx={{ mt: [50, 0], mx: [16, 0] }}
          >
            {t("Registration.code_invalid")}
            <Close
              ml="auto"
              mr={-2}
              onClick={() => setIsTokenError(false)}
              data-testid="OtpPage-error-alert-close-icon"
            />
          </Alert>
        ) : null} */}
        <Card
          data-testid="OtpPage-card"
          sx={{
            width: ["100%", "50%"],
            px: ["16px", 0],
            mt: [50, 200],
          }}
        >
          <Flex
            data-testid="OtpPage-card-wrapper"
            mt={25}
            sx={{
              flexDirection: "column",
            }}
          >
            <Heading data-testid="OtpPage-card-heading">
              {t("Registration.enter_verification")}
            </Heading>
            <Text
              data-testid="OtpPage-card-sms-text"
              mt={25}
              sx={{
                fontWeight: "weight_400",
                color: "shade_600",
              }}
            >
              {t("Registration.sms")}
            </Text>
            <Label
              data-testid="OtpPage-card-label"
              mt={25}
              sx={{
                fontSize: "subText",
                color: "shade_800",
              }}
            >
              {t("Registration.code_lable")}
            </Label>
            <Input
              data-testid="OtpPage-card-input"
              sx={{
                p: "17px",
                border: "1px solid",
                borderColor: "borderColor",
                borderRadius: "6px",
              }}
              mt={25}
              type="number"
              autoFocus={true}
              value={securityCode}
              onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                  TagManager.dataLayer({ 
                    dataLayer: {
                      event: "Click_event",
                      Page_title: "User registration | Enter verification code",
                      Action: "Confirm verification code"
                    }
                  });
                  handleVerifySms();
                }
              }}
              onChange={(e) => {
                setSecurityCode(e.target.value);
              }}
              placeholder={t("Registration.enter_code")}
              required
            />
            {verifyOTPData?.errorId && (
              <Flex pt={2} data-testid="OtpPage-card-error-flex">
                <>
                <ErrorIcon
                  color={"raspberry_500"}
                  data-testid="OtpPage-card-error-icon"
                />
                <Text
                  data-testid="OtpPage-card-invalid-code"
                  pl={2}
                  color="raspberry_500"
                  sx={{
                    fontSize: "subText",
                    fontWeight: "weight_400",
                  }}
                >
                  <>
                  {t("Registration.code_invalid")}
                  </>
                </Text>
                </>
              </Flex>
            )}

            <Flex
              data-testid="OtpPage-card-resend-code-flex"
              mt={25}
              sx={{
                justifyContent: "space-between",
                alignItems: ["flex-start", "center"],
                flexDirection: ["column-reverse", "row"],
              }}
            >
              {counter === 0 ? (
                <Flex
                  onClick={() => {
                    TagManager.dataLayer({ 
                      dataLayer: {
                        event: "Click_event",
                        Page_title: "User registration | Enter verification code",
                        Action: "Resend verification code"
                      }
                    });
                    resendCode();
                  }}
                  sx={{ alignItems: "center", cursor: "pointer" }}
                >
                  <ReSendIcon color={"primary"} />
                  <Text
                    ml={2}
                    color="royalBlue_500"
                    sx={{
                      fontWeight: "weight_400",
                      lineHeight: "27.2px",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    {t("Registration.resend_code")}
                  </Text>
                </Flex>
              ) : (
                <Text
                  data-testid="OtpPage-card-resend-code-flex-text"
                  color="shade_400"
                >
                  {t("Registration.resend_code_in")} {counter}
                </Text>
              )}
              <Button
                data-testid="OtpPage-card-resend-code-flex-button"
                onClick={handleVerifySms}
                variant={`${loading ? "gray" : "primary"}`}
                sx={{
                  boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
                  height: 49,
                  width: ["100%", "169px"],
                  mb: [20, 0],
                  borderRadius: "40px",
                  cursor: "pointer",
                }}
              >
                {t("confirm")}
              </Button>
            </Flex>
          </Flex>
        </Card>
      </Flex>

  );
}

export default OtpPage;
