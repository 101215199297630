import { Box, Flex, Button, Alert, Close } from "theme-ui";
import { useTranslation } from "react-i18next";
import GrandTotal from "./GrandTotal";
import DepositeDetails from "./DepositeDetails";
import Loader from "../../../Shared/Loader";
import { CloseIcon } from "@brinks/common/Icons/Components/close_modal";
import { WarningIcon } from "@brinks/common/Icons/Components/warning";
import { ReactComponent as SuccessIcon } from "@brinks/common/Icons/web_deposit.svg";
// @ts-ignore
import Modal from "react-modal";
import { useEffect, useLayoutEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@brinks/common/hooks/hooks";

import {
  submitSealBag,
  resetSealbagState,
  getDenominations,
  setLocation,
  setCashDevice,
  setCashDeviceLoading,
} from "@brinks/common/reducers/registerSealbagSlice";
import TagManager from "react-gtm-module";
import { getCurrentUser } from "../../../utils/secure-storage-utils";
import { setCurrentMerchant } from "@brinks/common/reducers/merchantsDetailSlice";
import { setCleanLocation } from "@brinks/common/reducers/storeLocationSlice";

const bg = {
  overlay: {
    position: "fixed",
    zIndex: 1020,
    top: 0,
    left: 0,
    width: "420",
    height: "322",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  content: {
    backgroundColor: "white",
    width: "45rem",
    maxWidth: "23%",
    padding: "30px",
    maxHeight: "50%",
    overflowY: "auto",
    position: "relative",
    borderRadius: "16px",
  },
};

export default function ReviewDeposite({
  updateStep,
  previousStep,
  tagType,
}: any) {
  const [tagTitle, setTagTitle] = useState("");

  useEffect(() => {
    if (tagType === "depositSealbag") {
      setTagTitle("Deposit sealbag | Review your deposit");
    } else if (tagType === "orderPickup") {
      setTagTitle("Order Management | Order pickup | Review order");
    } else {
      setTagTitle("Order Management | Order change | Review order");
    }
  }, [tagType]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        Page_title: tagTitle,
      },
    });
  }, [tagTitle]);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const user = getCurrentUser();

  const [isConfirmationModalOpen, toggleConfirmationModal] = useState(false);
  const [isSuccessModalOpen, toggleSuccessModal] = useState(false);
  const [depositError, setDepositError] = useState(false);

  const {
    grandTotal,
    declaredValues,
    bagNumber,
    loading,
    loadingSubmitSealBag,
    preAnnouncementsResponse,
    selectedLocation,
    selectedCashDevice,
    isPreAnnouncementError,
  } = useAppSelector((state) => state.registerSealbagSlice);
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (preAnnouncementsResponse && preAnnouncementsResponse.length > 0) {
      setCashDeviceLoading(false);
      toggleSuccessModal(!isSuccessModalOpen);
    } else {
      setCashDeviceLoading(false);
    }
  }, [preAnnouncementsResponse]);

  const submitDeposit = () => {
    toggleConfirmationModal(!isConfirmationModalOpen);
    setCashDeviceLoading(true);
    const preAnnouncements = [
      {
        storeLocationId: selectedLocation.id,
        sealBag: {
          barcode: bagNumber,
          type: "barcode",
          description: "",
          actualTotal: 0,
          declaredTotal: grandTotal,
          cashDeviceId: selectedCashDevice?.id,
        },
        type: "COMMERCIAL",
        status: "CREATED",
        preparedBy: user.id,
        userName: `${user?.profile?.firstName} ${user?.profile?.lastName}`,
        customerNotes: "",
        statusModificationDate: new Date().toISOString(),
        source: "WEB",
        senderCountry: "NL",
        declaredValues: declaredValues?.filter((el: any) => {
          return el.denominationQuantity !== 0;
        }),
      },
    ];

    dispatch(submitSealBag({ preAnnouncements }));
    dispatch(getDenominations({}));
  };

  const cleanUpTheStates = () => {
    dispatch(setCurrentMerchant(null));
    dispatch(setCleanLocation(null));
    dispatch(setLocation(null));
    dispatch(setCashDevice(null));
  };

  if (loading || loadingSubmitSealBag) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  return (
    <>
      <GrandTotal />
      <Flex mt={32}>
        <DepositeDetails />
      </Flex>

      <Flex mr={140} mt={20} sx={{ flexDirection: "row-reverse" }}>
        <Button
          mt={20}
          variant="primary"
          onClick={() => {
            TagManager.dataLayer({
              dataLayer: {
                event: "Click_event",
                Page_title: tagTitle,
                Action: "Confirm",
              },
            });

            if (grandTotal > 5000) {
              setDepositError(true);
              return;
            } else {
              toggleConfirmationModal(!isConfirmationModalOpen);
            }
          }}
          data-testid="register-sealbag-step1-next-btn"
          sx={{
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
            height: 49,
            width: ["100%", "169px"],
            pointerEvents: `auto`,
            borderRadius: "40px",
            cursor: "pointer",
            float: "right",
          }}
        >
          {t("ManageSealBags.confirm")}
        </Button>
        <Button
          onClick={() => {
            TagManager.dataLayer({
              dataLayer: {
                event: "Click_event",
                Page_title: tagTitle,
                Action: "Cancel",
              },
            });
            dispatch(resetSealbagState());
            dispatch(setCurrentMerchant(null));
            dispatch(setCleanLocation(null));
            dispatch(setLocation(null));
            dispatch(getDenominations({}));
            cleanUpTheStates();
            updateStep(1);
          }}
          mt={20}
          mr={20}
          variant="muted"
          data-testid="register-sealbag-step1-next-btn"
          sx={{
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
            height: 49,
            width: ["100%", "169px"],
            pointerEvents: `auto`,
            borderRadius: "40px",
            cursor: "pointer",
            backgroundColor: "background",
            color: "royalBlue_500",
            border: "1px solid",
            borderColor: "royalBlue_500",
          }}
        >
          {t("ManageSealBags.cancel")}
        </Button>
      </Flex>
      <Modal
        backdropOpacity={0.5}
        closeTimeoutMS={500}
        isOpen={isConfirmationModalOpen}
        style={bg}
        transparent={true}
        center
      >
        <Box>
          <Flex sx={{ flexDirection: "row-reverse" }}>
            <Box
              sx={{
                cursor: "pointer",
              }}
              onClick={() => {
                TagManager.dataLayer({
                  dataLayer: {
                    event: "Click_event",
                    Page_title: tagTitle,
                    Action: "Cancel",
                  },
                });
                toggleConfirmationModal(!isConfirmationModalOpen);
              }}
            >
              <CloseIcon
                color={"shade_300"}
                style={{
                  cursor: "pointer",
                }}
              />
            </Box>
          </Flex>
          <Flex
            mt={10}
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column-reverse",
            }}
          >
            <WarningIcon color={"shade_300"} />
          </Flex>
          <Flex
            pt={10}
            sx={{
              fontFamily: "heading",
              fontWeight: "bold",
              fontSize: "heading_4",
              alignContent: "center",
              lineHeight: "register_bag",
              flexDirection: "column-reverse",
              textAlign: "center",
            }}
          >
            <>
              {t("ManageSealBags.confirmation")}
              {isConfirmationModalOpen &&
                TagManager.dataLayer({
                  dataLayer: {
                    event: "Click_event",
                    Page_title: tagTitle,
                    Action: "Review your deposit pop-up",
                  },
                })}
            </>
          </Flex>
          <Flex
            pt={10}
            sx={{
              fontFamily: "body",
              fontWeight: "normal",
              fontSize: "body",
              lineHeight: "label",
              color: "beaver",
              textAlign: "center",
            }}
          >
            {t("ManageSealBags.please_confirm")}
          </Flex>
          <Flex sx={{ flexDirection: "row-reverse" }}>
            <Button
              mt={20}
              variant="primary"
              onClick={() => {
                TagManager.dataLayer({
                  dataLayer: {
                    event: "Click_event",
                    Page_title: tagTitle,
                    Action: "Yes, make deposit",
                  },
                });
                submitDeposit();
              }}
              data-testid="register-sealbag-step1-next-btn"
              sx={{
                boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
                height: 49,
                pointerEvents: `auto`,
                borderRadius: "40px",
                cursor: "pointer",
                float: "right",
              }}
            >
              {t("ManageSealBags.yes_make_deposit")}
            </Button>
            <Button
              mt={20}
              mr={20}
              variant="muted"
              onClick={() => {
                TagManager.dataLayer({
                  dataLayer: {
                    event: "Click_event",
                    Page_title: tagTitle,
                    Action: "Cancel",
                  },
                });
                toggleConfirmationModal(!isConfirmationModalOpen);
              }}
              data-testid="register-sealbag-step1-next-btn"
              sx={{
                boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
                height: 49,
                width: ["100%", "120px"],
                pointerEvents: `auto`,
                borderRadius: "40px",
                cursor: "pointer",
                backgroundColor: "white",
                color: "royalBlue_500",
                border: "1px solid",
                borderColor: "royalBlue_500",
              }}
            >
              {t("ManageSealBags.go_back")}
            </Button>
          </Flex>
        </Box>
      </Modal>
      <Modal
        backdropOpacity={0.5}
        closeTimeoutMS={500}
        isOpen={isSuccessModalOpen}
        style={bg}
        transparent={true}
        center
      >
        <Box>
          <Flex sx={{ flexDirection: "row-reverse" }}>
            <Box
              sx={{
                cursor: "pointer",
              }}
              onClick={() => {
                TagManager.dataLayer({
                  dataLayer: {
                    event: "Click_event",
                    Page_title: tagTitle,
                    Action: "Cancel",
                  },
                });
                dispatch(resetSealbagState());
                dispatch(getDenominations({}));
                toggleSuccessModal(!isSuccessModalOpen);
                cleanUpTheStates();
                updateStep(1);
              }}
            >
              <CloseIcon
                color={"shade_300"}
                style={{
                  cursor: "pointer",
                }}
              />
            </Box>
          </Flex>
          <Flex
            mt={20}
            mb={20}
            sx={{
              display: "flex",
              alignItems: "center",

              flexDirection: "column-reverse",
            }}
          >
            <Flex
              sx={{
                border: "4px solid",
                borderColor: "royalBlue_500",
                borderRadius: "50%",
                padding: 13,
              }}
            >
              <SuccessIcon />
            </Flex>
          </Flex>
          <Flex
            pt={10}
            sx={{
              fontFamily: "heading",
              fontWeight: "bold",
              fontSize: "heading_4",
              alignContent: "center",
              lineHeight: "register_bag",
              flexDirection: "column-reverse",
              textAlign: "center",
            }}
          >
            {t("ManageSealBags.success")}
          </Flex>
          <Flex
            pt={10}
            mb={30}
            sx={{
              fontFamily: "body",
              fontWeight: "normal",
              fontSize: "body",
              lineHeight: "label",
              color: "beaver",
              flexDirection: "column-reverse",
              textAlign: "center",
            }}
          >
            <>
              {t("ManageSealBags.deposit_sent")}
              {TagManager.dataLayer({
                dataLayer: {
                  event: "Click_event",
                  Page_title: "Deposit sealbag | Add deposit",
                  Action: "Add deposit - Success",
                },
              })}
            </>
          </Flex>
        </Box>
      </Modal>
      {isPreAnnouncementError && (
        <Alert
          data-testid="error-alert"
          sx={{
            position: "fixed",
            left: "50%",
            top: "13%",
            width: 496,
            boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.1)",
            borderRadius: 4,
            fontSize: "subText",
            fontWeight: "weight_400",
          }}
        >
          {"Some thing went wrong!"}
          <Close
            ml="auto"
            sx={{
              cursor: "pointer",
            }}
            mr={-2}
            onClick={() => {
              dispatch(resetSealbagState());
              cleanUpTheStates();
              updateStep(1);
            }}
            data-testid="error-alert-close-icon"
          />
        </Alert>
      )}

      {depositError && (
        <Alert
          data-testid="error-alert"
          sx={{
            position: "fixed",
            left: "50%",
            top: "13%",
            width: 496,
            boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.1)",
            borderRadius: 4,
            fontSize: "subText",
            fontWeight: "weight_400",
          }}
        >
          {t("ManageSealBags.max_amount_msg")}
          <Close
            ml="auto"
            sx={{
              cursor: "pointer",
            }}
            mr={-2}
            onClick={() => {
              setDepositError(false);
            }}
            data-testid="error-alert-close-icon"
          />
        </Alert>
      )}
    </>
  );
}
