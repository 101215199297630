// ---------------------------------------------------------
// EXTERNAL IMPORTS
// ---------------------------------------------------------
import { useTranslation } from "react-i18next";
import { Box, Flex, Text, Divider } from "theme-ui";

// ---------------------------------------------------------
// INTERNAL IMPORTS
// ---------------------------------------------------------
import { UserRow } from "./UserRow";
import { UserLocation } from "../../../../Hooks/useUserWithLocations";

// ---------------------------------------------------------
// TYPES
// ---------------------------------------------------------
interface UserListProps {
  users: UserLocation[];
}

export const UserList = ({ users }: UserListProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Flex
        pl={40}
        py={30}
        bg="white"
        data-testid="UserList"
        sx={{
          borderRadius: 8,
          flexDirection: "column",
          borderBottom: "1px solid",
          borderColor: "borderColor",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Flex>
          <Box sx={{ width:'20%', textAlign: 'left' }}>
            <Text color="shade_800">{t("UserList.name")}</Text>
          </Box>
          <Box sx={{ width:'30%', textAlign: 'left' }}>
            <Text color="shade_800">{t("UserList.location")}</Text>
          </Box>
          <Box sx={{ width:'20%', textAlign:'left' }}>
            <Text color="shade_800">{t("UserList.merchant")}</Text>
          </Box>
          <Box sx={{ width:'15%', textAlign:'center' }}>
            <Text color="shade_800">{t("UserList.status")}</Text>
          </Box>
          <Box sx={{ width:'15%', textAlign:'center' }}>
            <Text color="shade_800">{t("UserList.type")}</Text>
          </Box>
        </Flex>
        <Divider color="divider" mt={30} />
        {users.map((user) => {
          return <UserRow {...user} />
        })}
      </Flex>
    </>
  );
};

