import { Flex, Button, Box, Divider, Alert, Close } from "theme-ui";
import { useTranslation } from "react-i18next";
import LocationDetails from "../LocationDetails";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@brinks/common/hooks/hooks";
import TimeSlotDetails from "../timeSlotDetails";
import { useNavigate } from "react-router-dom";
import DenominationSummary from "./DenominationSummary";
import ConfirmationModal from "../ConfirmationModal";
import {
  setSuccessOrderChangeMsg,
  submitOrderChange,
  resetState,
  saveTransportOrder,
  setLoadingSaveOrder,
} from "@brinks/common/reducers/orderDetailsSlice";
import { resetWebFilter } from "@brinks/common/reducers/storeLocationSlice";
import { setBusinessRulesMoneyOrdersSkus } from "@brinks/common/reducers/businessRulesMoneyOrdersSkusSlice";
import { setBusinessRulesMoneyOrders } from "@brinks/common/reducers/businessRulesMoneyOrdersSlice";
import { setDenominations } from "@brinks/common/reducers/denominationsSlice";
import { setSkus } from "@brinks/common/reducers/skusDetailSlice";
import TagManager from "react-gtm-module";
import { getCurrentUser } from "../../../utils/secure-storage-utils";
import { OrderChangeType } from "@brinks/common/constants/orderChangeType";
import Loader from "../../../Shared/Loader";
import moment from "moment";

export default function ReviewOrderChangeDetails({ previousStep }: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isConfirmationModalOpen, toggleConfirmationModal] = useState(false);
  const [shouldResetStatus, setShouldResetStatus] = useState(false);
  const [isProcessingOrder, setIsProcessingOrder] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); 

  const {
    selectedPickupDate,
    grandTotal,
    declaredNoteValues,
    declaredCoinValues,
    selectedPickupLocation,
    orderChangeResponse,
    deliveryDateResponseError,
    selectedCurrency,
    customerReference,
    selectedTypeOfChange,
    countryDeviceId,
    loadingSaveOrder
  } = useAppSelector((state) => state.orderDetailsSlice);

  const { businessRulesMoneyOrders } = useAppSelector(
    (state) => state.businessRulesMoneyOrdersSlice
  );

  const { currentCurrency } = useAppSelector(
    (state) => state.denominationsSlice
  );
  
  const isPhysicalLocationNL = !!(selectedPickupLocation?.physicalLocation?.country === "NL");

  const shouldShowCalendar = businessRulesMoneyOrders
  ? businessRulesMoneyOrders[0].orderAllowCustomDates
  : false;
  
  const user = getCurrentUser();

  useEffect(() => {
    if (deliveryDateResponseError) {
      previousStep();
    }
  }, [deliveryDateResponseError]);

  useEffect(() => {
    if (
      orderChangeResponse &&
      orderChangeResponse.length > 0 &&
      shouldResetStatus
    ) {
      navigate(`/orders`);
      dispatch(resetState());
      dispatch(setSuccessOrderChangeMsg(true));
      dispatch(setBusinessRulesMoneyOrdersSkus(null));
      dispatch(setBusinessRulesMoneyOrders(null));
      dispatch(setDenominations(null));
      dispatch(setSkus(null));
    }
  }, [orderChangeResponse]);

  const dispatchPrepareAndDispatchOrders = (type: string, status: string) => {
    let currentNotes =
      declaredNoteValues != null
        ? declaredNoteValues?.filter((el: any) => {
            return el.denominationQuantity !== 0;
          })
        : [];

    let currentCoins =
      declaredCoinValues != null
        ? declaredCoinValues?.filter((el: any) => {
            return el.denominationQuantity !== 0;
          })
        : [];

    let moneyOrderRule: any = businessRulesMoneyOrders;

    toggleConfirmationModal(!isConfirmationModalOpen);
    const orders: any = [
      {
        preparedBy: `${user?.profile?.firstName} ${user?.profile?.lastName}`,
        storeId: selectedPickupLocation.id,
        serviceId: type == "TRANSPORT"? '55caee7d-4639-4325-9f37-bc7ec54d68eb' : moneyOrderRule[0].serviceId,
        storeName: selectedPickupLocation.name,
        storeLocationCode: selectedPickupLocation.locationCode,
        customerReference: customerReference,
        orderDateTime: new Date().toISOString(),
        type: type,
        status: status,
        deliveryDateTime: selectedPickupDate
          ? moment(selectedPickupDate).format("YYYY-MM-DD[T]00:00:00[Z]")
          : "",
        isFixedDelivery: false,
        totalAmount: grandTotal,
        currency: selectedCurrency.value,
        frequency: "ONCE",
        items: [],
      },
    ];
    if (type === "CHANGE") {
      orders[0].items.push(...currentNotes);
      orders[0].items.push(...currentCoins);
    }

    dispatch(submitOrderChange({ orders }));

    TagManager.dataLayer({
      dataLayer: {
        event: "Click_event",
        Page_title: "Order management | Order Change - Select Denomination",
        Action: "Delivery date " + orders[0].deliveryDateTime,
      },
    });

    TagManager.dataLayer({
      dataLayer: {
        event: "Click_event",
        Page_title: "Order management | Order Change - Select Denomination",
        Action: "Frequency " + orders[0].frequency,
      },
    });

    TagManager.dataLayer({
      dataLayer: {
        event: "Click_event",
        Page_title: "Order management | Order Change - Select Denomination",
        Action: "End date " + orders[0].endDate,
      },
    });

    TagManager.dataLayer({
      dataLayer: {
        event: "Click_event",
        Page_title:
          "Order management | Order Change - Order placed successfully",
        Action: "Change Order Successfull",
      },
    });
  };

  const submitOrder = () => {
    setIsProcessingOrder(true);
    dispatch(setLoadingSaveOrder(true));
    if (selectedTypeOfChange === OrderChangeType.ON_DEMAND) {
      const data = {
        partnerLocationId: countryDeviceId,
        deliveryDate: moment(selectedPickupDate).format(
          "YYYY-MM-DD[T]00:00:00.000[Z]"
        ),
        customerReference: customerReference,
        countryCode: "NL",
        type: "save"
      };
      dispatch(saveTransportOrder(data)).then((responseAction: any) => {
        const response = responseAction.payload;
        if (response.success) {
          // If successful, prepare and dispatch orders with type "TRANSPORT"
          dispatchPrepareAndDispatchOrders("CHANGE", "NEW");
          dispatchPrepareAndDispatchOrders("TRANSPORT", "SENT_TO_FULFILLMENT");
        } else {
          // If not successful, prepare and dispatch orders with type "TRANSPORT" and status "CANCELLED"
          dispatchPrepareAndDispatchOrders("CHANGE", "CANCELLED");
          dispatchPrepareAndDispatchOrders("TRANSPORT", "CANCELLED");
        }
        setShouldResetStatus(true);
        setIsProcessingOrder(false);
        dispatch(setLoadingSaveOrder(false));
      });
    } else if (selectedTypeOfChange === OrderChangeType.DYNAMIC) {
      dispatchPrepareAndDispatchOrders("CHANGE", "NEW");
      setShouldResetStatus(true);
      setIsProcessingOrder(false);
      dispatch(setLoadingSaveOrder(false));
    }

    setIsSubmitting(false);
  };

  if (isProcessingOrder || loadingSaveOrder || isSubmitting) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  return (
    <>
      <LocationDetails order="change" customerReference={customerReference} />
      <DenominationSummary />
      {((selectedTypeOfChange === OrderChangeType.ON_DEMAND &&
        isPhysicalLocationNL) ||
        !isPhysicalLocationNL && shouldShowCalendar) && (
        <TimeSlotDetails
          startDate={selectedPickupDate}
          heading={t("ManageOrders.delivery_details")}
          dateLable={t("ManageOrders.delivery_date")}
        />
      )}
      <Flex mt={20} sx={{ flexDirection: "row-reverse" }}>
        <Button
          mt={20}
          variant="primary"
          disabled={isSubmitting}
          onClick={() => {
            TagManager.dataLayer({
              dataLayer: {
                event: "Click_event",
                Page_title: "Order management | Order Change - Review Order",
                Action: "Confirm",
              },
            });

            toggleConfirmationModal(!isConfirmationModalOpen);
          }}
          data-testid="register-sealbag-step1-next-btn"
          sx={{
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
            "&:disabled": {
              bg: "shade_200",
              color: "shade_400",
              cursor: "not-allowed",
            },
            height: 49,
            width: ["100%", "169px"],
            pointerEvents: `auto`,
            borderRadius: "40px",
            cursor: "pointer",
            float: "right",
          }}
        >
          {t("ManageSealBags.confirm")}
        </Button>
        <Button
          onClick={() => {
            TagManager.dataLayer({
              dataLayer: {
                event: "Click_event",
                Page_title: "Order management | Order Change - Review Order",
                Action: "Cancel",
              },
            });
            dispatch(resetState());
            dispatch(resetWebFilter());
            navigate(`/orders`);
          }}
          mt={20}
          mr={20}
          variant="muted"
          data-testid="register-sealbag-step1-next-btn"
          sx={{
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
            height: 49,
            width: ["100%", "169px"],
            pointerEvents: `auto`,
            borderRadius: "40px",
            cursor: "pointer",
            backgroundColor: "background",
            color: "royalBlue_500",
            border: "1px solid",
            borderColor: "royalBlue_500",
          }}
        >
          {t("ManageSealBags.cancel")}
        </Button>
      </Flex>
      <ConfirmationModal
        toggleConfirmationModal={toggleConfirmationModal}
        isConfirmationModalOpen={isConfirmationModalOpen}
        submit={() => {
          submitOrder();
          setIsSubmitting(true);
        }}
        confirmationMsg={t("ManageOrders.orderChange_confirmation")}
        btnText={t("ManageOrders.place_order")}
        orderType={"change"}
      />
    </>
  );
}
