// ---------------------------------------------------------
// EXTERNAL IMPORTS
// ---------------------------------------------------------
import TagManager from "react-gtm-module";
import Select, { StylesConfig } from "react-select";

import { useTranslation } from "react-i18next";
import { useState, useEffect, CSSProperties } from "react";

// ---------------------------------------------------------
// INTERNAL IMPORTS
// ---------------------------------------------------------
import { MerchantsAndLocations } from "@brinks/common/api/Api";
import { Input } from "theme-ui";

// ---------------------------------------------------------
// STYLE
// ---------------------------------------------------------
const customControlStyles: CSSProperties = {
  height: "50px",
  width: "300px",
  paddingLeft: "12px",
  margin: "16px 12px 12px 0",
  boxShadow: "0px 4px 8px rgb(0 0 0 / 10%)",
};

const customStyles: StylesConfig<any> = {
  control: (provided, state) => {
    return { ...provided, ...customControlStyles };
  },
  dropdownIndicator: (provided, state) => ({
    paddingTop:'5px',
    color:'shade_400',
    paddingRight:"20px",
  }),
};

// ---------------------------------------------------------
// TYPES
// ---------------------------------------------------------
interface Options {
  label: string;
  value: string;
}

interface LocationListMerchantSelectorProps {
  locationsMerchants: MerchantsAndLocations[]; 
  onChange: (status: null | string) => void;
}

export const LocationListMerchantSelector = ({ onChange, locationsMerchants }: LocationListMerchantSelectorProps) => {
  const { t } = useTranslation();

  const [options, setOptions] = useState<Options[]>([
    { value: 'ALL', label: t("UserLocationFilter.all") }
  ]);

  useEffect(() => {
    handleGenerateOptions(locationsMerchants);
  }, [locationsMerchants]);

  const handleGenerateOptions = (locationsMerchants: MerchantsAndLocations[]) => {

    const optionsToAdd: Options[] = [];

    if (locationsMerchants)
    {
      for (const { id, name } of locationsMerchants) {
        optionsToAdd.push({ value: `${id}`, label: `${name}` });
      }
    }
    setOptions([{ value: 'ALL', label: t("UserLocationFilter.all") }, ...optionsToAdd]);
  }

  const handleChange = (e: any) => {
    TagManager.dataLayer({ 
      dataLayer: {
        event: "Click_event",
        Action: "Merchant " + e.value,
        Page_title: "User management | User overview",
      }
    });

    const option = options.find((option) => option.value === e.value);
    
    if(option) {
      onChange(option.value === "ALL" ? null : option.value);
    }
  };

  return options.length === 2 && options.find(x => x.value !== "ALL") ? (
    <Input
      type="text"
      contentEditable={false}
      placeholder={`${t(
        "ManageLocations.merchant_placeholder"
      )}: ${options[1].label.toUpperCase()}`}
      value={options[1].label}
      sx={{
        bg: "white",
        border: "1px solid",
        borderColor: "shade_200",
        borderRadius: 8,
        textAlign: "left",
        width: 343,
        mt: 1,
        mr: 1
      }}
    />
  ) : (
    <Select
      options={options}
      styles={customStyles}
      onChange={handleChange}
      data-testid="advanceFilter-UserLocationMerchant-select"
      placeholder={`${t("ManageLocations.merchant_placeholder")}: ${options ? "" : options[0]}`}
    />
  );
}
