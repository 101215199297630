/** @jsxImportSource theme-ui */
import { Text, Flex } from "theme-ui";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Header from "../Header/Header";
import { useAppDispatch } from "@brinks/common/hooks/hooks";
import { resetSealbagState } from "@brinks/common/reducers/registerSealbagSlice";
import { resetState } from "@brinks/common/reducers/orderDetailsSlice";

export default function Sidebar({ sideMenus }: { sideMenus: any }) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <>
      <Flex
        data-testid="flex-sidebar-nav"
        sx={{
          flexDirection: "column",
          height: "100vh",
          alignItems: "center",
          borderRight: "1px solid",
          borderColor: "shade_200",
          px: 24,
          position: "fixed",
          width: "25%",
        }}
      >
        <Header />
        <nav style={{width:'100%'}}>
          {sideMenus &&
            sideMenus.map((menu: any, key: any) => (
              <NavLink
              
               state={{path:menu.name}}
                style={({ isActive }) => {
                  return {
                    display: "block",
                    width: "100%",
                    borderRadius: "8px",
                    textDecoration: "none",
                    marginBottom: 20,
                    fontSize: "body",
                    lineHeight: "22.4px",
                    backgroundColor: isActive ? "rgb(112, 166, 245,0.2)" : "",
                  };
                }}
                to={`/${menu.name}`}
                onClick={() => {
                  dispatch(resetSealbagState());
                  dispatch(resetState());
                }}
                key={key}
                children={({ isActive }) => {
                  return (
                    <>
                      <Flex
                        pt={16}
                        pb={16}
                        pl={25}
                        sx={{
                          width: "80%",
                          height: "54px",
                          alignItems: "center",
                        }}
                      >
                        <Flex
                          pr={17}
                          sx={{
                            width: 40,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {isActive ? menu.icon_active : menu.icon_standard}
                        </Flex>
                        <Text
                          sx={{
                            color: isActive ? "primary" : "black",
                          }}
                        >
                          {t(menu.label)}
                        </Text>
                      </Flex>
                    </>
                  );
                }}
              />
            ))}
        </nav>
      </Flex>
    </>
  );
}
