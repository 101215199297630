import { Text, Flex, Heading, Divider, Button, Alert, Close } from "theme-ui";

import { useAppSelector, useAppDispatch } from "@brinks/common/hooks/hooks";
import {
  handleDeclaredQuantity,
  setGrandTotal,
  setTotalWithCurrency,
  resetSealbagState,
  getDenominations,
} from "@brinks/common/reducers/registerSealbagSlice";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import DenominationRow from "./DinomiantionRow";
import { DownIcon } from "@brinks/common/Icons/Components/down";
import { formatCurrency } from "@brinks/common/utils";
import { resetWebFilter } from "@brinks/common/reducers/storeLocationSlice";
import TagManager from "react-gtm-module";
import { getSkus } from "@brinks/common/reducers/skusDetailSlice";

export default function Denominations({
  nextStep,
  updateStep,
  denominationsFormated,
}: any) {
  useEffect(() => {
    TagManager.dataLayer({ 
      dataLayer: {
        event: "page_view",
        Page_title: "Deposit sealbag | Denomination and quantity"
      } 
    })
  }, []);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isValid, setIsvalid] = useState(false);
  const { grandTotal, declaredValues, totalWithCurrency } = useAppSelector(
    (state) => state.registerSealbagSlice
  );
  const [total, setTotal] = useState(grandTotal);
  const [showMoreIndex, setShowMoreIndex] = useState(4);
  const [declaredDeposit, setDeclaredDeposit] = useState<any[]>([]);
  const [isShowMore, setIsShowMore] = useState(true);
  const [isAmountError, setIsAmountError] = useState(false);


  const { selectedLocation} = useAppSelector(
    (state) => state.registerSealbagSlice
  );

  const { denominations } = useAppSelector(
    (state) => state.registerSealbagSlice
  );

  const { skus } = useAppSelector(
    (state) => state.skusDetailSlice
  );

  useEffect(() => {
    if (selectedLocation && selectedLocation.merchant) {
      dispatch(getDenominations({currency: selectedLocation.merchant.currency}));
      dispatch(getSkus({}));  
    }
    
  }, [selectedLocation]);

useEffect(() => {
  TagManager.dataLayer({
    dataLayer: {
      event: "page_view",
      Page_title: "Deposit sealbag | Add deposit",
    },
  });
    const arr: any[] = [];

  if (skus && denominations) {

    denominations.forEach((denomination) => {
      const mySkuObject = skus.find((sku) => sku.denominationId === denomination.id);
      
      if (mySkuObject && mySkuObject.denomination?.type === 'Note') {
        let declarObj: any = {
          denominationId: mySkuObject?.denominationId,
          skuId: mySkuObject.id,
          totalPrice: 0,
          currency: mySkuObject?.denomination?.currency,
          denominationQuantity: 0,
          quantity: 0,
          faceValue: mySkuObject?.denomination?.faceValue,
          unitPrice: mySkuObject?.price,
          total: 0,
          type: "ORDER_PRODUCT",
          currencyType: denomination.type,
          status: "New",
          description: mySkuObject?.description,
        };

        arr.push(declarObj);
      }
    });

    if (declaredValues && declaredValues.length > 0) {
      // Update arr with values from declaredValues
      declaredValues.forEach((declaredCoin: any) => {
        const index = arr.findIndex(
          (item) => item.denominationId === declaredCoin.denominationId
        );
        if (index !== -1) {
          // Update denominationQuantity, declaredDateTime, and declaredValue
          arr[index].denominationQuantity = declaredCoin.denominationQuantity;
          arr[index].declaredDateTime = declaredCoin.declaredDateTime;
          arr[index].declaredValue = declaredCoin.declaredValue;
        }
      });
    }


    setDeclaredDeposit(arr.sort((a, b) => a.faceValue - b.faceValue));
  }
}, [denominations, skus]);



  const handleQuantity = (type: string, id: string, input: any) => {
    var newData = declaredDeposit.map((el: any) => { 
      if (el.denominationId === id) {
        if (type === "plus") {
          TagManager.dataLayer({ 
            dataLayer: {
              event: "Click_event",
              Page_title: "Deposit sealbag | Denomination and quantity",
              Action: "Add amount " + el.faceValue + " " + el.currency
            } 
          });
          return Object.assign({}, el, {
            denominationQuantity: el.denominationQuantity + 1,
            declaredDateTime: new Date().toISOString(),
            declaredValue: el.faceValue * (el.denominationQuantity + 1),
          });
        } else if (type === "input") {
          TagManager.dataLayer({ 
            dataLayer: {
              event: "Click_event",
              Page_title: "Deposit sealbag | Denomination and quantity",
              Action: "Input amount " + el.faceValue + " " + el.currency
            } 
          });
          return Object.assign({}, el, {
            denominationQuantity: Number.isNaN(input) ? 0 : parseInt(input),
            declaredDateTime: new Date().toISOString(),
            declaredValue: el.faceValue * input,
          });
        } else {
          TagManager.dataLayer({ 
            dataLayer: {
              event: "Click_event",
              Page_title: "Deposit sealbag | Denomination and quantity",
              Action: "Minus amount " + el.faceValue + " " + el.currency
            } 
          });
          return Object.assign({}, el, {
            denominationQuantity: el.denominationQuantity - 1,
            declaredDateTime: new Date().toISOString(),
            declaredValue: el.faceValue * (el.denominationQuantity - 1),
          });
        }
      }
      return el;
    });
    setDeclaredDeposit(newData);
    dispatch(handleDeclaredQuantity(newData));
    calculateTotal(newData);
    if (isValid) {
      setIsvalid(false);
    }
  };

  const onUpdateTotal = (data: any) => {
    setTotal(data);
  };

  const calculateTotal = (newData: any) => {
    let total = 0;
    newData.map((x: any) => {
      total += x.denominationQuantity * x.faceValue;
    });
    dispatch(setGrandTotal(total));
    const euro = formatCurrency(total, "EUR", "nl-NL");
    onUpdateTotal(euro);
    TagManager.dataLayer({ 
      dataLayer: {
        event: "Click_event",
        Page_title: "Deposit sealbag | Denomination and quantity",
        Action: "Total deposit amount = " + euro
      } 
    });
    dispatch(setTotalWithCurrency(euro));
  };

  const showMore = () => {
    setIsShowMore(false);
    setShowMoreIndex(declaredDeposit.length);
  };

  const onNextStep = () => {
    if (grandTotal !== 0 && grandTotal !== 0.0) {
      TagManager.dataLayer({ 
        dataLayer: {
          event: "Click_event",
          Page_title: "Deposit sealbag | Denomination and quantity",
          Action: "Next"
        } 
      });
      if(grandTotal > 5000){
        setIsAmountError(true)
      }else{
        nextStep();
      }
     
    } else {
      setIsvalid(true);
    }
  };

  return (
    <>
      <Flex
        bg="white"
        sx={{
          px: 24,
          py: 24,
          borderBottom: "1px solid",
          borderColor: "borderColor",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: 8,
          flexDirection: "column",
        }}
        data-testid="LocationList"
      >
        <Flex
          my={16}
          sx={{
            width: 375,
            justifyContent: "space-between",
          }}
        >
          <Text
            sx={{
              color: "shade_800",
            }}
          >
            {t("ManageSealBags.denomination")}
          </Text>
          <Text
            sx={{
              color: "shade_800",
              width: 150,
            }}
          >
            {t("ManageSealBags.qty")}
          </Text>
        </Flex>

        {declaredDeposit.length > 0 &&
          declaredDeposit?.map((el, index) =>
            index <= showMoreIndex ? (
              <DenominationRow
                key={el.denominationId}
                data={el}
                handleQuantity={handleQuantity}
              />
            ) : null
          )}

        {isShowMore && (
          <Text
            onClick={() => showMore()}
            mt={20}
            color="royalBlue_500"
            sx={{
              fontStyle: "bold",
              pr: "5px",
              cursor: "pointer",
            }}
          >
            {t("ManageSealBags.show_more")} <DownIcon color={"primary"} />
          </Text>
        )}

        {isValid && (
          <Flex mt={20} color="red">
            {t("ManageSealBags.select_denomination_msg")}
          </Flex>
        )}
        {isAmountError && (
        <Alert
          data-testid="error-alert"
          sx={{
            position: "fixed",
            left: "50%",
            top: "13%",
            width: 496,
            boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.1)",
            borderRadius: 4,
            fontSize: "subText",
            fontWeight: "weight_400",
          }}
        >
          {t("ManageSealBags.max_amount_msg")}
          <Close
            ml="auto"
            sx={{
              cursor: "pointer",
            }}
            mr={-2}
            onClick={() => {
              setIsAmountError(false);
            }}
            data-testid="error-alert-close-icon"
          />
        </Alert>
      )}

        <Divider color="divider" mt={50} />
        <Flex
          my={16}
          mb={100}
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Text
            sx={{
              color: "black",
              fontWeight: "weight_400",
            }}
          >
            {t("ManageSealBags.grand_total")}
          </Text>
          <Heading
            sx={{
              fontSize: "heading_4",
              color: "black",
              width: 150,
              pl: 4,
              ml: -311,
            }}
          >
            {totalWithCurrency}
          </Heading>
          <Flex
            sx={{
              alignSelf: "flex-end",
            }}
          >
            <Button
              onClick={() => {
                TagManager.dataLayer({ 
                  dataLayer: {
                    event: "Click_event",
                    Page_title: "Deposit sealbag | Denomination and quantity",
                    Action: "Cancel"
                  } 
                });
                dispatch(resetSealbagState());
                dispatch(resetWebFilter());
                // dispatch(getDenominations({currency: currentCurrency}));
                updateStep(1);
              }}
              mr={20}
              variant="muted"
              data-testid="register-sealbag-step1-next-btn"
              sx={{
                boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
                height: 49,
                width: ["100%", "169px"],
                pointerEvents: `auto`,
                borderRadius: "40px",
                cursor: "pointer",
                backgroundColor: "white",
                color: "royalBlue_500",
                border: "1px solid",
                borderColor: "royalBlue_500",
              }}
            >
              {t("ManageSealBags.cancel")}
            </Button>
            <Button
              onClick={(e: any) => onNextStep()}
              sx={{
                width: 164,
                height: 48,
                borderRadius: 40,
                px: 12,
                py: 16,
              }}
            >
              {t("ManageSealBags.next")}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
